import React, { useEffect, useState } from 'react'
import banner from '../../assets/storage/poker/banner.png';
import DatePicker from "react-datepicker";
import calendar from '../../assets/storage/homescreen/icons/calendar.svg'
import clock from '../../assets/storage/homescreen/icons/clock.svg'
import access from '../../assets/storage/homescreen/icons/group-301.svg'
import dress from '../../assets/storage/homescreen/icons/group-300.svg'
import pdf from '../../assets/storage/homescreen/icons/pdf.svg';
import arrowDown from '../../assets/storage/poker/arrow-down.svg'
import moment from 'moment';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
  } from "../components/Accordion";

import Typography from "@mui/material/Typography";
import axios from 'axios';
import StripTags from '../components/StripTags';
import apiUrl from '../../config';
import { useLocation } from 'react-router-dom';
const Poker = () => {

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [expanded, setExpanded] = useState("panel1");
    const [calendarData, setCalendarData] = useState({});
    const [calendarTable, setCalendarTable] = useState([]);
    const [tournamentData, setTournamentData] = useState({});
    const [tournamentTable, setTournamentTable] = useState([]);
    const [cashData, setCashData] = useState({});
    const [cashCategories, setCashCategories] = useState([]);
    const [cashTable, setCashTable] = useState([]);
    const [results, setResults] = useState({});
    const [resultTable, setResultTable] = useState([]);
    const [standardPrize, setStandardPrize] = useState([]);
    const [standardBreaks , setStandardBreaks] = useState([]);
    const [prizeStructure, setPrizeStructure] = useState([]);
    const [blindEventPrize, setBlindEventPrize] = useState([]);
    const [blindEventBreaks , setBlindEventBreaks] = useState([]);
    const [selectedBuyIn, setSelectedBuyIn] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedBuyInResult, setSelectedBuyInResult] = useState('');
    const [selectedTypeResult, setSelectedTypeResult] = useState('');
    const [selectedRows,setSelectedRows] = useState(10);
    const [filtered, setFiltered] = useState([]);
    const [filteredResult, setFilteredResult] = useState([]);

    const location = useLocation();
    const searchTab = location?.search?.split('?tab=')[1];

    const calendarTableBuyIns = [...new Set(calendarTable?.map(({ buy_in }) => buy_in))].sort();
    const calendarTableTypes = [...new Set(calendarTable?.map(({ type }) => type))].sort();

    const resultBuyIns = [...new Set(resultTable?.map(({ buy_in }) => buy_in))].sort();
    const resultTypes = [...new Set(resultTable?.map(({ type }) => type))].sort();

    useEffect(()=> {
        const fetchCalendarEvents = async () => {
            const res = await axios.get(`${apiUrl}/api/poker/calendar/events`);
            setCalendarData(res.data.event);
            setCalendarTable(res.data.table);
        } 
        fetchCalendarEvents();

        const fetchSpecialTournaments = async () => {
            const res = await axios.get(`${apiUrl}/api/poker/tournaments`);
            setTournamentData(res.data.tournament)
            setTournamentTable(res.data.table);
        } 
        fetchSpecialTournaments();

        const fetchCashGames = async () => {
            const res = await axios.get(`${apiUrl}/api/poker/cash/game`);
            setCashData(res.data.cash)
            setCashCategories(res.data.categories);
            setCashTable(res.data.table);
        } 
        fetchCashGames();

        const fetchResults = async () => {
            const res = await axios.get(`${apiUrl}/api/poker/results`);
            setResults(res.data.result)
            setResultTable(res.data.table);
        } 
        fetchResults();

        const fetchStandardPrize = async () => {
            const res = await axios.get(`${apiUrl}/api/poker/standard/prize`);
            setStandardPrize(res.data.standards)
            setStandardBreaks(res.data.breaks);
        } 
        fetchStandardPrize();


        const fetchEventStandardPrize = async () => {
            const res = await axios.get(`${apiUrl}/api/poker/blind/event`);
            setBlindEventPrize(res.data.standards)
            setBlindEventBreaks(res.data.breaks);
        } 
        fetchEventStandardPrize();

        const fetchPrizeStructure = async () => {
            const res = await axios.get(`${apiUrl}/api/poker/prize`);
            setPrizeStructure(res.data)
        } 
        fetchPrizeStructure();
    },[])

    const formattedFromDate = moment(fromDate).format('YYYY-MM-DD');
    const formattedToDate = moment(toDate).format('YYYY-MM-DD');

    const handleSubmit = (e) => {
        e.preventDefault();
        const filteredData = calendarTable.filter((data) => {
          const matchedBuyIn = !selectedBuyIn || data.buy_in.includes(selectedBuyIn);
          const matchedType = !selectedType || data.type.includes(selectedType);
          const matchedDate =
            (!fromDate || !toDate || moment(data.date).isBetween(formattedFromDate, formattedToDate, 'day', '[]'));
          return matchedBuyIn && matchedType && matchedDate;
        });
        setFiltered(filteredData);
    };

    const handleResult = (e) => {
        e.preventDefault()
        const filteredData = resultTable.filter((data) => {
            const matchedBuyIn = !selectedBuyInResult || data.buy_in.includes(selectedBuyInResult);
            const matchedType = !selectedTypeResult || data.type.includes(selectedTypeResult);
            const matchedDate = (!fromDate || !toDate || moment(data.date).isBetween(formattedFromDate, formattedToDate, 'day', '[]'));
            return matchedBuyIn && matchedType && matchedDate;
        });
        setFilteredResult(filteredData.slice(0, selectedRows));
    }

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <>
           <div className='poker'>
                <div className='navs py-5'>
                    <div className='container p-4 text-center'>
                        <ul class="nav nav-pills nav-fill justify-content-center flex-md-nowrap mb-3 w-100" id="pills-tab" role="tablist">
                            <li class="nav-item w-100 br-prime" role="presentation">
                                <button class={`nav-link fs-18 ${searchTab === 'calendar-of-events' ? 'active' : ''}`} id="pills-customer-service-tab" data-bs-toggle="pill" data-bs-target="#pills-customer-service" type="button" role="tab" aria-controls="pills-customer-service" aria-selected="false">Calendar Of Events</button>
                            </li>
                            {/* <li class="nav-item w-100 bl-prime br-prime" role="presentation">
                                <button class={`nav-link fs-18 ${searchTab === 'special-tournaments' ? 'active' : ''}`} id="pills-about-tab" data-bs-toggle="pill" data-bs-target="#pills-about" type="button" role="tab" aria-controls="pills-about" aria-selected="false">Special Tournaments</button>
                            </li> */}
                            <li class="nav-item w-100 bl-prime br-prime" role="presentation">
                                <button class={`nav-link fs-18 ${searchTab === 'cash-games' ? 'active' : ''}`} id="pills-cash-tab" data-bs-toggle="pill" data-bs-target="#pills-cash" type="button" role="tab" aria-controls="pills-cash" aria-selected="false">Cash Games</button>
                            </li>
                            <li class="nav-item w-100 bl-prime br-prime" role="presentation">
                                <button class={`nav-link fs-18 ${searchTab === 'blinds-&-prize' ? 'active' : ''}`} id="pills-blinds-tab" data-bs-toggle="pill" data-bs-target="#pills-blinds" type="button" role="tab" aria-controls="pills-blinds" aria-selected="false">Blinds & Prize Structure</button>
                            </li>
                            {/* <li class="nav-item w-100 br-prime" role="presentation">
                                <button class={`nav-link fs-18 ${searchTab === 'results' ? 'active' : ''}`} id="pills-contact-directory-tab" data-bs-toggle="pill" data-bs-target="#pills-contact-directory" type="button" role="tab" aria-controls="pills-contact-directory" aria-selected="false">Results</button>
                            </li> */}
                        </ul>
                    </div>
                    <div class="tab-content" id="pills-tabContent">
                        {/* Calendar Of Events */}
                        <div class={`tab-pane fade ${searchTab === 'calendar-of-events' ? 'active show' : ''} w-100`} id="pills-customer-service" role="tabpanel" aria-labelledby="pills-customer-service-tab">

                        {/* {calendarData?.image && ( */}
                        <div className='w-60 mx-auto p-4'>
                            <div className='picture-wrapper padding-top-40'>
                                <img src={calendarData?.image} alt='banner-table' />
                            </div>  
                        </div>
                        {/* )} */}

                        <div className='w-85 w-sm-90 mx-auto my-1'>
                         
                            {calendarTable.length > 0 ?  
                                ( 
                                <div className='table-responsive'>
                                    <table className='w-100 rounded-table'>
                                        <thead className="position-relative">
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white bg-prime'>Date</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white bg-prime'>Start Time</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white bg-prime'>Type</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white bg-prime'>Description</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white bg-prime'>Buy-In</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white bg-prime'>Starting Chips</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white bg-prime'>End Reg.</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white bg-prime'>Cap.</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white bg-prime'>Venue</th>
                                            <span className='table-rounded-v2'></span>
                                        </thead>
                                    <tbody>
                                        {calendarTable?.map((data,id) => (
                                            <tr key={id} style={{ backgroundColor: id % 2 === 1 ? 'rgba(146, 114, 56, 0.2)' : 'inherit' }}>
                                                <td className="text-center py-3">
                                                    <span>{data?.date}</span>
                                                </td>
                                                <td className="text-center py-3">
                                                    <span>{data?.start_time}</span>
                                                </td>
                                                <td className="text-center py-3">
                                                    <span>{data?.type}</span>
                                                </td>
                                                <td className="text-center py-3">
                                                    <span><StripTags policyText={data?.description} /></span>
                                                </td>
                                                <td className="text-center py-3">
                                                    <span>{data?.buy_in}</span>
                                                </td>
                                                <td className="text-center py-3">
                                                    <span>{data?.starting_chips}</span>
                                                </td>
                                                <td className="text-center py-3">
                                                    <span>{data?.end_reg}</span>
                                                </td>
                                                <td className="text-center py-3">
                                                    <span>{data?.cap}</span>
                                                </td>
                                                <td className="text-center py-3">
                                                    <span>{data?.venue}</span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                                ) : (
                                    <div class="msg-title">Poker Events to be announced soon. Stay Tuned!</div>
                                )
                            }
                              
                            </div>
                            <div className='w-60 mx-auto px-4 pt-3'>
                                {calendarData?.pdf && (
                                    <>
                                        <div className='d-flex align-items-center justify-content-center gap-2 my-2'>
                                            <img className='w-3' src={pdf} alt="pdf" />
                                            <span className='text-prime fs-20 fw-bold pdf-link' style={{textDecoration: 'underline'}}><a href={calendarData?.pdf}>Rules and Regulations</a></span>
                                        </div>
                                    </>
                                )}      
                            </div>
                            <div class="text-center">
                                <>
                                    {calendarData?.text && (
                                        <StripTags policyText={calendarData?.text} />
                                    )} 
                                </>
                            </div>
                        </div>
                        
                        {/* Special Tournaments */}
                        <div class={`tab-pane fade ${searchTab === 'special-tournaments' ? 'active show' : ''} w-100`} id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab">
                        <div className='w-60 mx-auto p-4'>
                                <div className='main-title '><span className='h3'>{tournamentData?.title}</span></div>
                                <div className='main-description'>
                                    <p className='text-center fs-18 w-75'>
                                        <StripTags policyText={tournamentData?.text} />
                                    </p>
                                </div>

                                <div className='d-flex align-items-center justify-content-center gap-2 mt-5 mb-2'>
                                        <img className='w-3' src={pdf} alt="pdf" />
                                        <span className='text-prime fw-bold fs-20 pdf-link' style={{textDecoration: 'underline'}}><a href={tournamentData?.pdf}>Rules and Regulations</a></span>
                                </div>

                                <div className='picture-wrapper padding-top-40 mt-4'>
                                    <img src={tournamentData?.image} alt='banner-table' />
                                </div>
                        </div>
                        <div className='w-75  w-sm-90 mx-auto my-4'>
                            <div className="table-responsive">
                                    <table className='w-100'>
                                        <thead className="position-relative bg-prime">
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Start Date</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Event Name</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Event Description</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Buy-In</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Casino Fees</th>
                                            <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Venue</th>
                                            <div className='table-rounded-v2'></div>
                                        </thead>
                                        <tbody>
                                            {tournamentTable && tournamentTable?.map((table,id)=> (
                                                <tr key={id}>
                                                    <td className="text-center py-3">
                                                        <span>{table?.start_date}</span>
                                                    </td>
                                                    <td className="text-center py-3">
                                                        <span>{table?.event_name}</span>
                                                    </td>
                                                    <td className="text-center py-3">
                                                        <span>{table?.event_description}</span>
                                                    </td>
                                                    <td className="text-center py-3">
                                                        <span>{table?.buy_in}</span>
                                                    </td>
                                                    <td className="text-center py-3">
                                                        <span>{table?.casino_fees}</span>
                                                    </td>
                                                    <td className="text-center py-3">
                                                        <span>{table?.venue}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* Cash Games */}
                        <div class={`tab-pane fade ${searchTab === 'cash-games' ? 'active show' : ''} w-100`} id="pills-cash" role="tabpanel" aria-labelledby="pills-cash-tab">
                        <div className='w-60  mx-auto p-4'>
                        {cashData && (
                            <>
                             <div className='main-title '><span className='h3'>{cashData?.title}</span></div>
                             <div className='main-description'>
                                 <p className='text-center fs-18 w-75'>
                                     <StripTags policyText={cashData?.text} />
                                 </p>
                             </div>
 
                             <div className='d-flex justify-content-center gap-sm-5 my-5'>
                                <div className='d-flex flex-column flex-sm-row align-items-center'>
                                    <img className='pt-4 width-25' src={clock} alt="clock" />
                                    <div className='d-flex flex-column ps-sm-3'>
                                        <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Opening Hours</span>
                                        <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={cashData?.opening_hour} /></span>
                                    </div>
                                </div>
                                <div className='d-flex flex-column flex-sm-row align-items-center'>
                                    <img className='pt-4 width-25' src={access} alt="clock" />
                                    <div className='d-flex flex-column mx-4 mx-sm-0 ps-sm-3'>
                                        <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Access</span>
                                        <span className='text-prime fs-18 text-center text-sm-start'>Membership Required</span>
                                    </div>
                                </div>
                                <div className='d-flex flex-column flex-sm-row align-items-center'>
                                    <img className='pt-4 width-25' src={dress} alt="clock" />
                                    <div className='d-flex flex-column ps-sm-3'>
                                        <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Dress Code</span>
                                        <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={cashData?.dress_code} /></span>
                                    </div>
                                </div>
                            </div>
 
                             <div className='picture-wrapper padding-top-40 mt-4'>
                                 <img src={cashData?.image} alt='banner-table' />
                             </div>
                            </>
                        )}
                       
                        </div>
                        <div className='w-65  w-sm-90 mx-auto'>
                            {cashCategories && cashCategories?.map(({title,id},index) => (
                              <div className='mt-4 mb-6'>
                                <div key={index} className='text-center text-prime my-3'><span className='fw-bold h4'>{title}</span></div>

                                <div className='table-responsive'>
                                    <table className='w-100'>
                                        <thead className="position-relative bg-prime">
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Minimum Buy In</th>
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Maximum Buy In</th>
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Small Blinds</th>
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Big Blinds</th>
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Rake</th>
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Cap.</th>
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Pts Earned</th>
                                            <div className='table-rounded-v2'></div>
                                        </thead>
                                        <tbody>
                                            {cashTable && cashTable.filter((data) => data.game_id === id).map((data,id)=> (
                                                 <>
                                                 <tr key={id}>
                                                   <td className="text-center py-2">
                                                       <span>{data?.minimum_buy_in}</span>
                                                   </td>
                                                   <td className="text-center py-2">
                                                       <span>{data?.maximum_buy_in}</span>
                                                   </td>
                                                   <td className="text-center py-2">
                                                       <span>{data?.blinds_S_B}</span>
                                                   </td>
                                                   <td className="text-center py-2">
                                                       <span>{data?.blinds_B_B}</span>
                                                   </td>
                                                   <td className="text-center py-2">
                                                       <span>{data?.rake}</span>
                                                   </td>
                                                   <td className="text-center py-2">
                                                       <span>{data?.cap}</span>
                                                   </td>
                                                   <td className="text-center py-2">
                                                       <span>{data?.pts_earned}</span>
                                                   </td>
                                                 </tr>
                                               </>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            ))}
                                                
                            <div style={{marginTop: '-45px'}}><hr /></div>
                                                
                            {/* {cashData?.pdf && (
                                <div className='d-flex align-items-center justify-content-center gap-2 mt-5 mb-3'>
                                    <img className='w-3' src={pdf} alt="pdf" />
                                    <span className='text-prime fw-bold fs-20 pdf-link' style={{textDecoration: 'underline'}}><a href={cashData?.pdf}>Rules and Regulations</a></span>
                                </div>
                            )}
                         */}
                            </div>
                        </div>  
                        {/* Blinds & Prize  */}
                        <div class={`tab-pane fade ${searchTab === 'blinds-&-prize' ? 'active show' : ''} w-100`} id="pills-blinds" role="tabpanel" aria-labelledby="pills-blinds-tab">
                            <div className='w-60 mx-auto p-4'>
                                <div className='main-title '><span className='h3'>Texas Hold’em Tournaments</span></div>
                               
                                <div className='picture-wrapper padding-top-40 mt-4'>
                                    <img src={banner} alt='banner-table' />
                                </div>

                            </div>   
                        <div className='w-65  w-sm-90 mx-auto'>
                        <div className="w-100 my-4">
                            <Accordion
                            expanded={expanded === "accordion1"}
                            onChange={handleChange("accordion1")}
                            >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                            >
                                <div className='fw-bold'>
                                    Standard Blinds Structure
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <div className='my-4'>
                                <div className='table-responsive'>
                                    <table className='w-100'>
                                        <thead className="position-relative bg-prime" style={{zIndex:'1'}}>
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Level</th>
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Small Blind</th>
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Big Blind</th>
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Ante</th>
                                            <th className='py-2 px-5 px-lg-0 fs-18 text-center text-white'>Duration</th>
                                            <div className='table-rounded-v2'></div>
                                        </thead>
                                        <tbody>
                                            {standardPrize && standardPrize?.map((data,index) => (
                                                <>
                                                <tr key={index}>
                                                    <td className="text-center py-2">
                                                        <span>{data?.level}</span>
                                                    </td>
                                                    <td className="text-center py-2">
                                                        <span>{data?.small_blind}</span>
                                                    </td>
                                                    <td className="text-center py-2">
                                                        <span>{data?.big_blind}</span>
                                                    </td>
                                                    <td className="text-center py-2">
                                                        <span>{data?.ante}</span>
                                                    </td>
                                                    <td className="text-center py-2">
                                                        <span>{data?.duration}</span>
                                                    </td>
                                                </tr>
                                                {standardBreaks && standardBreaks?.filter(({level}) => level === data?.level)?.map((data,index) => (
                                                    <>
                                                        <tr key={index}>
                                                            <td colSpan={5} className=" bg-prime text-center py-2" style={{ backgroundColor: '#927238'}}>
                                                                <span className='h5 fw-bold text-white'>{data?.break}</span>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                                
                                                </>
                                            ))}
                                
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </AccordionDetails>
                            </Accordion>
                        </div>

                        <div className="mt-4">
                            <Accordion
                            expanded={expanded === "accordion2"}
                            onChange={handleChange("accordion2")}
                            >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                            >
                                <div className='fw-bold'>
                                    2/3 - Day Event - Blinds Structure
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='my-4'>
                                    <div className='table-responsive'>
                                        <table className='w-100'>
                                            <thead className="position-relative bg-prime" style={{zIndex:'1'}}>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Level</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Small Blind</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Big Blind</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Ante</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Duration</th>
                                                <div className='table-rounded-v2'></div>
                                            </thead>
                                            <tbody>
                                                {blindEventPrize && blindEventPrize?.map((data,index) => (
                                                    <>
                                                    <tr key={index}>
                                                        <td className="text-center py-3">
                                                            <span>{data?.level}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.small_blind}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.big_blind}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.ante}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.duration}</span>
                                                        </td>
                                                    </tr>
                                                    {blindEventBreaks && blindEventBreaks?.filter(({level}) => level === data?.level)?.map((data,index) => (
                                                        <>
                                                            <tr key={index}>
                                                                <td colSpan={5} className="bg-prime text-center py-3" style={{ backgroundColor: '#927238'}}>
                                                                    <span className='h5 fw-bold text-white'>{data?.break}</span>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                    
                                                    </>
                                                ))}
                                    
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </AccordionDetails>
                            </Accordion>
                        </div>
                        {/* <div className="mt-4">
                        <Accordion
                            expanded={expanded === "accordion3"}
                            onChange={handleChange("accordion3")}
                            >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                            >
                                <div className='fw-bold'>
                                    Standard Prizes Structure
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <div className='my-4'>
                                <div className="table-responsive">
                                        <table className='w-100'>
                                            <thead className="position-relative bg-prime" style={{zIndex:'1'}}>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>Ranking</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>2/10 Players</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>3/10 Players</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>11/20 Players</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>21/30 Players</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>31/40 Players</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>41/50 Players</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>51/60 Players</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>61/75 Players</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>76/100 Players</th>
                                                <th className='py-3 px-5 px-lg-0 fs-18 text-center text-white'>101/125 Players</th>
                                                <div className='table-rounded-v2'></div>
                                            </thead>
                                            <tbody className='tbody-2'>
                                                {prizeStructure && prizeStructure?.map((data,index) => (
                                                    <tr key={index}>
                                                        <td className="text-center py-3">
                                                            <span>{data?.ranking}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.player1}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.player2}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.player3}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.player4}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.player5}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.player6}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.player7}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.player8}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.player9}</span>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <span>{data?.player10}</span>
                                                        </td>
                                                  </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </AccordionDetails>
                            </Accordion>
                        </div> */}
                        </div>  
                        </div> 
                        {/* Results */}
                        {/* <div class={`tab-pane fade ${searchTab === 'results' ? 'active show' : ''} w-100`}  id="pills-contact-directory" role="tabpanel" aria-labelledby="pills-contact-directory-tab">
                            <div className='w-60 mx-auto p-4'>
                                {results && (
                                    <>
                                        <div className='main-title'><span className='h3'>{results?.title}</span></div>

                                        <div className='picture-wrapper padding-top-40 mt-5'>
                                            <img src={results?.image} alt='banner-table' />
                                        </div>  
                                    </>                  
                                )}
                        </div> */}
                        {/* <form onSubmit={handleResult}>
                        <div className=''>
                            <div className='row poker-info justify-content-center'> 
                                <div className='col-2 my-3'>
                                    <div class="dropdown v2">
                                        <button class="btn btn-secondary dropdown-toggle text-uppercase w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            {selectedBuyInResult ? selectedBuyInResult : 'All Buy-Ins'}
                                            <img className='ms-5 mb-1 w-auto' src={arrowDown} alt="" />
                                        </button>
                                        <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                            {resultBuyIns?.map((data,id) => (
                                                <>
                                                    <li style={{padding: '0 30px'}} key={id}><a class="dropdown-item" onClick={() => setSelectedBuyInResult(data)} >{data}</a></li>
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-2 my-3'>
                                    <div class="dropdown v2">
                                        <button class="btn btn-secondary dropdown-toggle text-uppercase w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            {selectedTypeResult ? selectedTypeResult : 'All Types'}
                                            <img className='mb-1 w-auto' style={{marginLeft: '3.75rem'}} src={arrowDown} alt="" />
                                        </button>
                                        <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                            {resultTypes?.map((data,id) => (
                                                <>
                                                    <li style={{padding: '0 30px'}} key={id}><a class="dropdown-item" onClick={() => setSelectedTypeResult(data)}>{data}</a></li>
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-2 my-3'>
                                    <div className='date'>
                                        <div className='b-prime d-flex align-items-center justify-content-between'>
                                            <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)}  dateFormat="yyyy-MM-dd" />
                                            <img src={calendar} alt="calendar" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-2 my-3'>
                                    <div className='date'>
                                        <div className='b-prime d-flex align-items-center justify-content-between'>
                                            <DatePicker selected={toDate} onChange={(date) => setToDate(date)} dateFormat="yyyy-MM-dd"  />
                                            <img src={calendar} alt="calendar" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-2 my-3'>
                                    <div class="dropdown v2">
                                        <button class="btn btn-secondary dropdown-toggle text-uppercase w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            {selectedRows} Rows
                                        </button>
                                        <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                            <li style={{padding: '0 30px'}}><a class="dropdown-item" onClick={() => setSelectedRows(5)}>5</a></li>
                                            <li style={{padding: '0 30px'}}><a class="dropdown-item" onClick={() => setSelectedRows(10)}>10</a></li>
                                            <li style={{padding: '0 30px'}}><a class="dropdown-item" onClick={() => setSelectedRows(50)}>50</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-2 my-3'>
                                    <div className='filter'>
                                    <button
                                        className="btn btn-secondary text-uppercase w-100"
                                        id="dropdownMenuButton1"
                                        aria-expanded="false"
                                        type="submit"
                                        >
                                        Filter
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </form> */}
                        {/* <div className='w-50 mx-auto my-4'>
                            <div className='table-responsive'>
                                    <table className='w-100 rounded-table'>
                                        <thead className="position-relative bg-prime">
                                            <th className='py-3 px-5 fs-18 text-center text-white'>Date</th>
                                            <th className='py-3 px-5 fs-18 text-center text-white'>Name</th>
                                            <th className='py-3 px-5 fs-18 text-center text-white'>Amount</th>
                                            <th className='py-3 px-5 fs-18 text-center text-white'>Rank</th>
                                            <div className=''></div>
                                        </thead>
                                        <tbody>
                                            {resultTable?.map((data, id) => (
                                                <tr key={id}>
                                                    <td className="text-center py-3">
                                                    <span>{moment(data.date).format('YYYY-MM-DD')}</span>
                                                    </td>
                                                    <td className="text-center py-3">
                                                    <span>{data?.name}</span>
                                                    </td>
                                                    <td className="text-center py-3">
                                                    <span>{data?.amount}</span>
                                                    </td>
                                                    <td className="text-center py-3">
                                                    <span>{data?.rank}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
    </>
  )
}

export default Poker
