import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import apiUrl from '../../config';

const SingleGallery = () => {

    const [galleries, setGalleries] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchGallery = async () => {
            const res = await axios.get(`${apiUrl}/api/gallery/sub-gallery/${id}`)
                setGalleries(res.data.galleries)
        }
        fetchGallery()
    },[id])

  return (
    <>
        <section id="gallery">
            <div className='container my-5'>
                <div className=''>
                    <div class="tab-content" id="pills-tabContent">
                        <div className='row gy-4'>
                            {galleries 
                                && galleries
                                .filter((item) =>  item?.sub_category?.id == id)
                                .map(({ image }, id) => (
                                    <div key={id} className="col-12 col-md-6 col-lg-4 cursor-pointer gallery">
                                        <img src={image} width="456" height="304" alt="Casino Premises" />
                                    </div>  
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default SingleGallery