import React, { useState } from 'react'
import banner from '../../assets/storage/about/mask-group-5.png';
import socialBanner from '../../assets/storage/about/dinner.png';
import image from '../../assets/storage/about/image-4.png';
import { useEffect } from 'react';
import StripTags from '../components/StripTags';
import axios from 'axios';
import apiUrl from '../../config';

const AboutUs = () => {


    const [legacy ,setLegacy] = useState([]);
    const [legacyStatus ,setLegacyStatus] = useState([]);
    const [managements ,setManagements] = useState([]);
    const [socials ,setSocials] = useState([]);
    const [activeYear, setActiveYear] = useState(1);
    const [socialDescription, setSocialDescription] = useState({});
    useEffect(()=> {
        const fetchAbout = async () => {
            const res = await axios.get(`${apiUrl}/api/aboutUs`);
            setLegacy(res.data.legacy);
            setLegacyStatus(res.data.status);
            setManagements(res.data.managements);
            setSocials(res.data.socials);
            setSocialDescription(res.data.social_description);
        } 
        fetchAbout();
    },[])


    const handleBulletClick = (index) => {
        setActiveYear(index);
    };


  return (
    <>
        <div className='about'>
            <div className='navs py-5'>
                <div className='container p-4 '>
                    <ul class="nav nav-pills nav-fill justify-content-center flex-md-nowrap mb-3 w-100" id="pills-tab" role="tablist">
                        <li class="nav-item w-100 br-prime" role="presentation">
                            <button class="nav-link active" id="pills-customer-service-tab" data-bs-toggle="pill" data-bs-target="#pills-customer-service" type="button" role="tab" aria-controls="pills-customer-service" aria-selected="true">The Company</button>
                        </li>
                        <li class="nav-item w-100 br-prime bl-prime" role="presentation">
                            <button class="nav-link" id="pills-about-tab" data-bs-toggle="pill" data-bs-target="#pills-about" type="button" role="tab" aria-controls="pills-about" aria-selected="false">The Management</button>
                        </li>
                        <li class="nav-item w-100 bl-prime" role="presentation">
                            <button class="nav-link" id="pills-contact-directory-tab" data-bs-toggle="pill" data-bs-target="#pills-contact-directory" type="button" role="tab" aria-controls="pills-contact-directory" aria-selected="false">Social Responsibility</button>
                        </li>
                    </ul>
                </div>

                <div class="tab-content" id="pills-tabContent">
                    {/* Company */}
                    <div class="tab-pane fade show active w-100" id="pills-customer-service" role="tabpanel" aria-labelledby="pills-customer-service-tab">
                        <div className='width-75 mx-auto p-4'>
                                <div className='main-title '><span className='h3'>The Legacy</span></div>
                                <div className='main-description'>
                                    <p className='text-center fs-18 w-75'>
                                        The nostalgia of the past, the glory of the present, the evolution of a legacy, more than half a century of<br/>
                                        excellence!
                                    </p>
                                </div>

                                {/* TimeLine */}

                                <div className='timeline my-5'>
                                    <div class="d-flex flex-column flex-md-row align-items-center">
                                        <div class="nav flex-row flex-md-column nav-pills  vertical" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            {legacy.map((tab,index) => (
                                                <button key={index} onClick={() => handleBulletClick(index)} class={`nav-link ${index === 0 ? 'active' : ''} `}
                                                id={`v-pills-${tab.title.replace(/\s+/g, '')}-tab`} data-bs-toggle="pill" data-bs-target={`#v-pills-${tab.title.replace(/\s+/g, '')}`} type="button" role="tab" aria-controls={`v-pills-${tab.title.replace(/\s+/g, '')}`} aria-selected={activeYear === 0  ? 'true' : 'false'}>
                                                    <span className='history fs-18'>{tab.year}</span>
                                                </button>
                                            ))}
                                            <div className='vertical-line'></div>
                                        </div>
                                        <div class="tab-content" id="v-pills-tabContent">
                                            {legacy && legacy?.map((data,index) => (
                                                <div key={index} class={`tab-pane fade ${index === 0 ? 'show active' : ''} `} id={`v-pills-${data?.title.replace(/\s+/g, '')}`} role="tabpanel" aria-labelledby={`v-pills-${data?.title.replace(/\s+/g, '')}-tab`}>
                                                    <div className='d-flex flex-column flex-xl-row'>
                                                        <div>
                                                            <img className='image-banner' src={data?.image} alt="image" style={{height:'200px'}}/>
                                                        </div>    
                                                        <div className='ms-xl-3 mt-3 mt-xl-0'>
                                                            <p className='fw-bold text-prime fs-20 mb-4'>{data?.title}</p>
                                                            <p className='w-100 about-description'>
                                                                <StripTags policyText={data?.text} />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className='main-title'><span className='h3'>Legal Status</span></div>

                                <div className="my-4">
                                    {legacyStatus && legacyStatus?.map((data, id) => (
                                         <div key={id} className='mb-4'>
                                            <span className='mb-2 d-inline-block fs-20 fw-bold text-prime text-uppercase'>{data?.title}</span>
                                            <p><StripTags policyText={data?.description} /></p>
                                        </div>
                                    ))}
                                </div>
                        </div>
                    </div>
                    {/* Management */}
                    <div class="tab-pane fade w-100" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab">
                        <div className='w-60 mx-auto p-4'>
                                <div className='main-title '><span className='h3'>The Management</span></div>
                                {managements && managements.map((data,index) => (
                                    <div className='text-center my-5'>
                                        {data?.is_management === 1 ? (
                                            <>
                                                <div>
                                                    <img class="management-banner" src={data?.image} alt=''/>
                                                </div>
                                                <div className='mb-5 mt-3'>
                                                    <span className='fs-25 text-prime font-times'>{data?.title}</span>
                                                    <p className='text-second fs-20 my-2'><StripTags policyText={data?.text} /></p>
                                                </div>
                                            </>
                                            ) : (
                                                <div></div>
                                            )
                                        }
                                       
                                    </div>
                                ))}
                                
                                <div className='main-title'><span className='h3'>Members Of The Board</span></div>

                                <div className="row my-5">
                                    {managements && managements?.filter((data) => data.is_management === 0)
                                    .map((data,index) => (
                                        <div key={index} className='col-lg-4'>
                                            <div className='text-center'>
                                            <div className='picture-wrapper padding-top-80'>
                                                <img src={data?.image} alt=''/>
                                            </div>
                                            <div className='mb-5 mt-3'>
                                                <span className='fs-25 text-prime font-times'>{data?.title}</span>
                                                <p className='text-second fs-20 my-2'><StripTags policyText={data?.text} /></p>
                                            </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                        </div>
                    </div>
                    {/* Social Responsibility */}
                    <div class="tab-pane fade w-100" id="pills-contact-directory" role="tabpanel" aria-labelledby="pills-contact-directory-tab">
                        <div className='w-60 mx-auto p-4'>
                                <div className='main-title '><span className='h3'>Social Responsibility</span></div>
                                <div className='my-4'>
                                    <div className='text-center w-100 fs-24 mb-4'>
                                        {socialDescription && (
                                                <StripTags policyText={socialDescription?.text} />
                                        )}
                                    </div>
                                    {socials && socials?.map((data,id) => (
                                        <div key={id}>
                                            <p className='text-center w-100 fs-18'>
                                            <b>{data?.title}</b><br />
                                                <StripTags policyText={data.text} />
                                            </p>
                                            <div className='picture-wrapper padding-top-40 my-4'>
                                                <img src={data?.image} alt='banner-table' />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AboutUs