

const settings2 = {
    infinite: true,
    dots: false,
    speed: 1000,
    autoplay: true,
    slidesToShow: 3,
    arrows: false, 
    swipeToSlide: true,
    variableHeight: false,
    responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
            breakpoint: 1440,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 350,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '20px'
          }
        }
      ]
}

const settings3 = {
    infinite: true,
    dots: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    initialSlide: 0,
    arrows: false, 
    swipeToSlide: true,
}

export { settings2 , settings3 }