import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import apiUrl from '../../config';
import StripTags from '../components/StripTags';
const Rules = () => {

    const [dressCode,setDressCode] = useState({});
    const [admissionPolicy, setAdmissionPolicy] = useState({});
    const [openingHours,setOpeningHours] = useState([]);
    const [accreditedPayment,setAccreditedPayment] = useState({});
    

    useEffect(() => {
        const fetchDressCode = async () => {
            const res = await axios.get(`${apiUrl}/api/rules/dress_code`);
            setDressCode(res.data);
        }
        fetchDressCode()

        const fetchAdmissionPolicy = async () => {
            const res = await axios.get(`${apiUrl}/api/rules/admission_policy`);
            setAdmissionPolicy(res.data);
        }
        fetchAdmissionPolicy()

        const fetchOpeningHour = async () => {
            const res = await axios.get(`${apiUrl}/api/rules/opening_hour`);
            setOpeningHours(res.data);
        }
        fetchOpeningHour()


        const fetchAccreditedPayment = async () => {
            const res = await axios.get(`${apiUrl}/api/rules/accredited_payment`);
            setAccreditedPayment(res.data);
        }
        fetchAccreditedPayment()
    }, [])
  

  return (
    <>
        <div className='rules'>
            <div className='navs py-5'>
                <div className='w-sm-65 p-4 text-center'>
                    <ul class="nav nav-pills nav-fill justify-content-center flex-md-nowrap mb-3 w-100" id="pills-tab" role="tablist">
                        <li class="nav-item w-100 br-prime" role="presentation">
                            <button class="nav-link fs-18 active" id="pills-customer-service-tab" data-bs-toggle="pill" data-bs-target="#pills-customer-service" type="button" role="tab" aria-controls="pills-customer-service" aria-selected="true">Accredited Payment</button>
                        </li>
                        <li class="nav-item w-100 br-prime bl-prime" role="presentation">
                            <button class="nav-link fs-18" id="pills-about-tab" data-bs-toggle="pill" data-bs-target="#pills-about" type="button" role="tab" aria-controls="pills-about" aria-selected="false">Admission Policy</button>
                        </li>
                        <li class="nav-item w-100 br-prime bl-prime" role="presentation">
                            <button class="nav-link fs-18" id="pills-rules-tab" data-bs-toggle="pill" data-bs-target="#pills-rules" type="button" role="tab" aria-controls="pills-rules" aria-selected="false">Opening Hours</button>
                        </li>
                        <li class="nav-item w-100 bl-prime" role="presentation">
                            <button class="nav-link fs-18" id="pills-contact-directory-tab" data-bs-toggle="pill" data-bs-target="#pills-contact-directory" type="button" role="tab" aria-controls="pills-contact-directory" aria-selected="false">Dress Code</button>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active w-100" id="pills-customer-service" role="tabpanel" aria-labelledby="pills-customer-service-tab">
                        <div className='w-60 mx-auto p-4'>
                                <div className='main-title'><span className='h3'>Acrredited Payment</span></div>
                                    {accreditedPayment && (
                                        <>
                                        <div className='main-description mb-5'>
                                                <p className='text-center fs-18 w-75'>
                                                    <StripTags policyText={accreditedPayment?.description} />
                                                </p>
                                        </div>
                                            <div className='picture-wrapper padding-top-40 my-4'>
                                                <img src={accreditedPayment?.image} alt='banner-table' />
                                            </div>
                                        </>
                                    )}
                        </div>
                    </div>
                    <div class="tab-pane fade w-100" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab">
                        <div className='w-60 mx-auto p-4'>
                                <div className='main-title '><span className='h3'>Admission Policy</span></div>
                                {admissionPolicy && (
                                    <>
                                        <div className='main-description mb-5'>
                                            <p className='text-center fs-18 w-75'>
                                                <StripTags policyText={admissionPolicy?.description} />
                                            </p>
                                        </div>
                                    
                                        <div className='picture-wrapper padding-top-40 my-4'>
                                            <img src={admissionPolicy?.image} alt='banner-table' />
                                        </div>
                                    </>
                                )}
                                
                        </div>
                    </div>
                    <div class="tab-pane fade w-100" id="pills-rules" role="tabpanel" aria-labelledby="pills-rules-tab">
                        <div className='w-60 mx-auto p-4'>
                                <div className='main-title '><span className='h3'>Opening Hours</span></div>
                                <div className='main-description mb-5'>
                                    <p className='text-center fs-18 w-75'>
                                        Casino Du Liban Opens Every Day Of The Year Except Catholic Good Friday.<br />
                                    </p>
                                </div>  
                                  
                             
                                {openingHours && (
                                    <>
                                    {openingHours?.map((openingHour,id) => (
                                        <>
                                            <div key={id} className='main-description mb-5'>
                                                <div>
                                                    <p className='text-prime fs-18 fw-bold'>{openingHour?.title}</p><StripTags policyText={openingHour?.text} /> 
                                                </div>
                                            </div>

                                            <div className='picture-wrapper padding-top-40 my-4'>
                                                <img src={openingHour?.image} alt='banner-table' />
                                            </div>
                                        </>
                                    ))}
                                        
                                    </>
                                )}
                             
                           
                        </div>
                    </div>
                    <div class="tab-pane fade w-100" id="pills-contact-directory" role="tabpanel" aria-labelledby="pills-contact-directory-tab">
                        <div className='w-60 mx-auto p-4'>
                                <div className='main-title '><span className='h3'>Dress Code</span></div>
                                {dressCode && (
                                    <>
                                        <div className='main-description'>
                                            <p className='text-center w-75 fs-18 my-2'>
                                                <StripTags policyText={dressCode?.description} /><br />
                                            </p>
                                        </div>

                                        <div className='picture-wrapper padding-top-40 my-4'>
                                            <img src={dressCode?.image} alt='banner-table' />
                                        </div>
                                        <div className='d-flex mt-4 align-items-center justify-content-center flex-column'>
                                                <p className='text-center w-100 fs-18 mt-2 mb-1'>
                                                    <span className="text-prime fw-bold">{dressCode?.first_title}</span><StripTags policyText={dressCode?.first_text} /><br />
                                                </p>
                                                <p className='text-center w-100 fs-18 my-2'>
                                                    <span className="text-prime fw-bold">{dressCode?.second_title}</span><StripTags policyText={dressCode?.second_text} />
                                                </p>
                                                <p className='text-center w-100 fs-18 my-2'>
                                                    <span className="text-prime fw-bold">{dressCode?.third_title}</span><StripTags policyText={dressCode?.third_text} />
                                                </p>
                                        </div>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default Rules