import React, { useEffect, useState } from 'react';
import { ReactSVG } from "react-svg";
import { settings3, settings2 } from '../components/Slick';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import clock from '../../assets/storage/homescreen/icons/clock.svg';
import exploreCitiesWhite from '../../assets/storage/homescreen/icons/exploreCities.svg';
import summerActivities from '../../assets/storage/homescreen/icons/summerActivites.svg';
import winterActivites from '../../assets/storage/homescreen/icons/winterActivites.svg';
import shopping from '../../assets/storage/homescreen/icons/shopping.svg';
import artCulture from '../../assets/storage/homescreen/icons/artCulture.svg';
import exploreCities from '../../assets/storage/homescreen/icons/Explore_Cities_Dark.svg'
import summerActivitiesWhite from '../../assets/storage/homescreen/icons/summerActivitiesWhite.svg';
import winterActivitesWhite from '../../assets/storage/homescreen/icons/winterActivitiesWhite.svg';
import shoppingWhite from '../../assets/storage/homescreen/icons/shoppingWhite.svg';
import artCultureWhite from '../../assets/storage/homescreen/icons/artCultureWhite.svg';
import location from '../../assets/storage/homescreen/icons/Icon-Map.svg';
import BetArabia from '../../assets/storage/homescreen/banner.jpg';
import Calendar from 'react-calendar';
import MinimizeIcon from '@mui/icons-material/Minimize';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import axios from 'axios';
import StripTags from '../components/StripTags';
import moment from 'moment';
import apiUrl from '../../config';

const Home = () => {

    const [value, onChange] = useState(new Date());
    const [banners, setBanners] = useState([]);
    const [news, setNews] = useState([]);
    const [gallery , setGallery] = useState([]);
    const [travels, setTravel] = useState([]);
    const [selectedCategoryNews, setSelectedCategoryNews] = useState('All');
    const [categoryNews, setCategoryNews] = useState([]);
    const [selectedCategoryTravel, setSelectedCategoryTravel] = useState('Explore Cities');
    const [categoryTravel ,setCategoryTravel] = useState([]);
    const [events, setEvents] = useState([]);
    const [latestEvent , setLatestEvent] = useState({});
    const [tableEvents, setTableEvents] = useState([]);
    const [selectedEventDate, setSelectedEventDate] = useState(null);
    const [announce, setAnnounce] = useState({});
    
    useEffect(()=> {
        const fetchData = async () => {
            const res = await axios.get(`${apiUrl}/api/home`);
            setNews(res.data.news);
            setGallery(res.data.galleries);
            setBanners(res.data.banners);
            setTravel(res.data.tourisms);
            setCategoryNews(res.data.news_categories);
            setCategoryTravel(res.data.tourism_categories);
            setEvents(res.data.events);
            setLatestEvent(res.data.latestEvent);
            setTableEvents(res.data.tableEvents);
        } 
        fetchData();

        const fetchAnnounce = async () => {
            try {
                const res = await axios.get(`${apiUrl}/api/announce`)
                setAnnounce(res?.data);
            } catch (error) {
                console.log(error);
            }
           
        }
        fetchAnnounce()
    },[])
    
    const dateFormat = moment(selectedEventDate).format('YYYY-MM-DD');

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        autoplay:true,
        autoplaySpeed: 3000,
        speed: 500,
        inifinite:true,
        slidesToScroll: 1,
        pauseOnHover:false,
    };

    function truncate(string,number)  {
        return string?.length > number ? string.substring(0,number-1)+ "..." : string
    }

    const [learnMore, setLearnMore] = useState(false);

    const filteredData = tableEvents.filter((data) => {
        const formatedDate =  moment.unix(data?.from_date).format('YYYY-MM-DD') === dateFormat;
        return formatedDate;
    })

    const categoryIcons = [
        { category: 'Explore Cities', activeIcon: exploreCitiesWhite, inactiveIcon: exploreCities },
        { category: 'Summer Activities', activeIcon: summerActivitiesWhite , inactiveIcon: summerActivities },
        { category: 'Winter Activities', activeIcon: winterActivitesWhite, inactiveIcon: winterActivites },
        { category: 'Shopping', activeIcon: shoppingWhite, inactiveIcon: shopping },
        { category: 'Art Culture', activeIcon: artCultureWhite , inactiveIcon: artCulture },
    ];    

    const [activeTab, setActiveTab] = useState(categoryIcons[0].category.replace(/\s+/g, ''));

    const handleTab = (tab) => {
        setActiveTab(tab)
    };

    function MySlider({settings}) {
        return (
          <Slider {...settings}>
            {travels
            .filter((data) => {
            const category = data.category?.title.toLowerCase();
            const selected = selectedCategoryTravel?.toLowerCase();
            const includesCategory = category.includes(selected);
            return includesCategory;
            })
            .map((data, index) => (
            <div className='travel-card picture-wrapper padding-top-100' key={index}>
                <img src={data.image} alt="" />
                <div className='w-75'>
                <div className='d-flex align-items-center justify-content-between'>
                    <span className='fs-20 fw-bold text-white text-uppercase'>{data.title}</span>
                    <span className=''><ReactSVG src={location} className="w-5" /></span>
                </div>
                <p className='text-white'><StripTags policyText={truncate(data?.location,100)} /></p>
                <p className='text-white fs-14'><Link className='text-decoration-none text-white' to={`/travel-to-lebanon/${data?.id}`}>Read More</Link></p>
                </div>
                <div className='overflow-black'></div>
            </div>
            ))}
          </Slider>
        );
      }


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    
    const handleLearnMoreClick = (eventDate) => {
        setSelectedEventDate(eventDate);
        setLearnMore(true);
        scrollToTop();
    };
    
    // Function to handle calendar date change
    const handleCalendarDateChange = (selectedDate) => {
    setSelectedEventDate(selectedDate);
    };

  return (
    <>
        <main className='position-relative'>                         
            {announce?.pdf && (
                <div className="cdl__button">
                    <button className='btn btn-cdl my-4'><Link className='text-decoration-none text-white' to={announce?.pdf}>{announce.title}</Link></button>
                </div>
             )}
            <div className='slider-banner position-relative'>
                <Slider {...settings}>
                    {banners && banners?.map((data,index) => (
                        <div key={index} className='picture-wrapper padding-top-45'>
                        <div className='overlay-banner'></div>
                        <picture>
                            <source media="(max-width: 575px)" srcset={data?.mobile_image} />
                            <img class="cover" src={data?.image} alt="Banner Calendar" />
                        </picture>
                            <div className='banner-title w-75'>
                                <p className='text-uppercase text-white fs-50 font-times fw-bold'>{data?.title}</p>
                                <p className='fs-50 font-times fw-normal text-white'>{data?.second_title}</p>
                                
                                {data?.button === 1 && (
                                    <button className='btn btn-prime btn-prime-3 my-4'><Link className='text-decoration-none text-white' to={`${data?.link}`} target="__blank">{data?.btn_title}</Link></button>
                                )}
                            </div>
                        </div>
                    ))}
                </Slider>
               
                {learnMore && (
                    <div className='learn-more p-4'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <span className='fs-32 fw-bold text-white'>WHAT’S HAPPENING</span>
                            <span onClick={() => setLearnMore(false)} className='fs-35 pb-3 fw-bold text-white'><MinimizeIcon fontSize='large' /></span>
                        </div>
                        <div className='row'>
            <div className='col-lg-3 my-4'>
                {/* <div className='list'>
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">All</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Event & Entertainment</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Table Games</button>
                    </li>
                </ul>
                </div> */}
                <Calendar onChange={handleCalendarDateChange} value={selectedEventDate} />
            </div>
            <div className='col-lg-8 py-4 px-5'>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                    {/* <span className='fs-18 fw-bold text-prime'>Event & Entertainment</span> */}
                    <div className='events-scroll'>
                        <div className='row'>
                            {filteredData.length > 0 ? filteredData.map((data,index) => (
                                <div key={index} className='col-6'>
                                    <div className='card-info my-3'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <span className='fs-16 fw-bold'>{data?.event?.title}</span>
                                            <span className='fs-14'>{data?.time}</span>
                                        </div>
                                        <p className='my-2'><StripTags policyText={truncate(data?.event?.description,75)} /></p>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <span className='fs-12 text-prime'>This Event Needs Reservation</span>
                                            <span className='fs-12'>
                                                <Link className='text-decoration-none text-heavy' to={`/events/${data?.event?.id}`}>
                                                    Learn more
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )) : (
                                <>
                                     <div className='d-flex justify-content-center align-items-center'>
                                        <span className='h2 text-prime'>
                                            No Events 
                                        </span>
                                    </div> 
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">...</div>
                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">...</div>
                <div class="tab-pane fade" id="pills-disabled" role="tabpanel" aria-labelledby="pills-disabled-tab" tabindex="0">...</div>
            </div>
            </div>
        </div>
    </div>
                )}
            </div>
                
            <div className='bg-prime banner-events' style={{'marginTop': '-10px'}}>
                <div className='w-50 w-sm-100 mx-auto py-2 py-lg-0 px-3 text-center d-flex flex-lg-row flex-column align-items-center'>
                    <p className='fs-35 fw-bold text-white text-uppercase'>What's Happening at casino du liban</p>
                    <button className='btn btn-events my-4 ms-lg-4'><Link className='text-decoration-none text-prime' to={'/events?tab=events'}>VIEW CALENDAR</Link></button>
                </div>
            </div>

            {/* Betarabia Banner */}
            <div class="text-center">
                <Link className='text-decoration-none text-white' target="_blank" to={`https://www.betarabia.com?utm_source=Casino&utm_medium=Casino&utm_campaign=SMS&utm_id=Safe`}>
                    <img class="w-100 h-auto img-fluid" src={BetArabia} alt="Betarabia" />
                </Link>
            </div>

             {/* Travel To Lebanon */}
             <section id="travel">
            <div className='my-5'>
                <div className='travel__title container'>
                    <span className='font-times'>Travel To Lebanon</span>
                </div>
                <div class="d-flex flex-lg-row flex-column justify-content-between">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            {categoryIcons.map((item, index) => (
                                <button
                                key={index}
                                className={`nav-link ${activeTab === item.category.replace(/\s+/g, '') ? 'active' : ''}`}
                                onClick={() => handleTab(item.category.replace(/\s+/g, ''))}
                                id={`v-pills-${item.category.replace(/\s+/g, '')}-tab`}
                                data-bs-toggle="pill"
                                data-bs-target={`#v-pills-${item.category.replace(/\s+/g, '')}`}
                                type="button"
                                role="tab"
                                aria-controls={`v-pills-${item.category.replace(/\s+/g, '')}`}
                                aria-selected={activeTab === item.category.replace(/\s+/g, '')}
                                >
                                <div className="d-flex align-items-center g-1" onClick={() => setSelectedCategoryTravel(item.category)}>
                                    <ReactSVG src={activeTab === item.category.replace(/\s+/g, '') ? item.activeIcon : item.inactiveIcon} className="travel__icons" />
                                    <span className="w-50 fs-18 fw-bold text-heavy">{item.category}</span>
                                </div>
                                </button>
                            ))}
                    </div>
                    <div class="tab-content" id="v-pills-tabContent">
                        {categoryIcons.map((item, index) => (
                            <div
                            key={index}
                            className={`tab-pane ${activeTab === item.category.replace(/\s+/g, '') ? 'show active fade' : ''}`}
                            id={`v-pills-${item.category.replace(/\s+/g, '')}`}
                            role="tabpanel"
                            aria-labelledby={`v-pills-${item.category.replace(/\s+/g, '')}-tab`}
                            >
                            <div className={`fade ${activeTab === item.category.replace(/\s+/g, '') ? 'show active' : ''}`} style={{ transition: 'opacity ease' }}>   
                                <div className='bg-prime'>
                                {categoryTravel && categoryTravel?.filter((category) => category.title === item.category)
                                        .map((data,id)=> (
                                            <div key={id}>
                                                
                                                <p className='text-white pb-2 pt-5 py-md-5 fs-20 w-75 width-75 travel-description'>
                                                    <StripTags policyText={data?.description} />
                                                </p>
                                            </div>
                                        ))}
                                    
                                    <div className='travel-gallery'>
                                        <div className='slider-container'>
                                            <MySlider settings={settings2} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
                </div>
                <div className='text-center'>
                    <button className='btn btn-prime my-4'><Link className='text-decoration-none text-white' to={'/travel-to-lebanon'}>Explore More</Link></button>
                </div>
            </section>

            {/* News */}
            {/* <section id="news">
                <div className="bg-prime pt-5">
                    <div className='container'>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className='news__left'>
                                <span className='font-times'>News</span>
                            </div>
                            <div className='news__right'>
                                <div class="d-flex align-items-start">
                                    <div class="nav nav-pills font-gotham-gold pb-2 p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <button class={`nav-link ${selectedCategoryNews === 'All' ? 'active' : ''}`} onClick={() => setSelectedCategoryNews('All')} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">All</button>
                                        {categoryNews && categoryNews?.map(({title}, index) => (
                                            <button class="nav-link" id={`pills-${index}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${index}`} type="button" role="tab" aria-controls={`pills-${index}`} aria-selected="false"  onClick={() => setSelectedCategoryNews(title)}>{title}</button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container py-5'>
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0">
                            <div className="row">
                            {news &&
                                news
                                    .filter((item) => {
                                        const categoryMatch = selectedCategoryNews === 'All' || item.category?.title == selectedCategoryNews; 
                                        return categoryMatch;
                                    })
                                    .map(({description, image, link, title, date, source, pdf}, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-lg-4 mb-5">
                                        <div className="picture-wrapper padding-top-55">
                                        <img src={image} alt="" />
                                        </div>
                                        <div className="news__info">
                                        <h2 className="text-prime font-times fs-35">{title}</h2>
                                        <div className="d-flex align-items-center gap-5 mt-2">
                                            <div className="d-flex align-items-center">
                                                <Link to={link} className="ms-2 fs-16 w-100 text-prime">
                                                    {source}
                                                </Link>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img className="w-20" src={clock} alt="" />
                                                <span className="me-2 fs-16 w-100 text-prime">
                                                    {date}
                                                </span>
                                            </div>
                                        </div>
                                        <p className="text-second my-4 fs-20">
                                            <StripTags policyText={description} />
                                        </p>
                                        </div>
                                        {link ? (
                                            <span className="link__news fs-16">
                                                <Link to={link}>READ MORE</Link>
                                            </span>
                                        ) : (
                                            <span className="link__news fs-16">
                                                <Link to={pdf}>READ MORE</Link>
                                            </span>
                                        )}        
                                    </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center'>
                    <button className='btn btn-prime my-4'><Link className='text-decoration-none text-white' to={'/news'}>Explore More</Link></button>
                </div>
            </section> */}

           



            {/* Gallery */}
            <section id="gallery">
                <div className='container'>
                    <div className='gallery__title'>
                        <span className='font-times'>Gallery</span>
                    </div>
                    <div className='row'>
                        {gallery && gallery?.map((data,index) => (
                            <div key={index} className='col-12 col-md-6 col-lg-4'>
                               <div className='picture-wrapper padding-top-85 mb-4 pointer'>
                                   <Link to={'/gallery/1'}>
                                       <div className='overlay-hover-prime'></div>
                                       <div className="pos-gallery-title"><span className='h3 text-white fw-bold'>{data?.title}</span></div>
                                       <img src={data?.image} alt="Casino Premises" />
                                   </Link>
                               </div>
                           </div>
                        ))}
                    </div>
                </div>
                <div className='text-center'>
                    <button className='btn btn-prime mb-5 mt-4'><Link className='text-decoration-none text-white' to={'/gallery'}>Explore More</Link></button>
                </div>
            </section>
        </main>
    </>
  )
}

export default Home