import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import apiUrl from '../../config';
const SubGallery = () => {

        const [categories,setCategories] = useState([]);
        const [subCategories, setSubCategories] = useState([]);
        const [galleries, setGalleries] = useState([]);
        const [selectedCategory,setSelectedCategory] = useState('');
        const [selectedYear, setSelectedYear] = useState('');
        const {id} = useParams();

        useEffect(() => {
            const fetchGallery = async () => {
                const res = await axios.get(`${apiUrl}/api/gallery/${id}`)
                setGalleries(res.data.galleries)
                setCategories(res.data.categories)
                setSubCategories(res.data.subcategories)
            }
            fetchGallery()
        },[id])
        
        useEffect(() => {
            const selected = categories.find(category => category?.id == id);
          
            if (selected) {
              setSelectedCategory(selected.title);
            } else {
              setSelectedCategory('All');
            }
          }, [categories, id]);
          
        
        const uniqueYears = [...new Set(subCategories?.map(({ year }) => year))].sort();
  return (
    <>
        <section id="gallery">
            <div className='container my-5'>
                <div className=''>
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class={`nav-link fs-18 ${selectedCategory === 'All' ? 'active' : ''}`} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected={selectedCategory === 'All' ? 'true' : 'false'} onClick={() => setSelectedCategory('All')}>All</button>
                        </li>
                        {categories && categories?.map(({title},id) => (
                            <li key={id} class="nav-item" role="presentation">
                                <button class={`nav-link fs-18 ${selectedCategory === title ? 'active' : ''}`} id={`pills-${title}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${title}`} type="button" role="tab" aria-controls={`pills-${title}`} aria-selected={selectedCategory === title ? 'true' : 'false'} onClick={() => setSelectedCategory(title)}>{title}</button>
                            </li>
                        ))}
                    </ul>
                    <div class="dropdown v2 mb-4">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                         {selectedYear ? selectedYear : 'All Years'}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" href="#" onClick={() => setSelectedYear('')}>All Years</a></li>
                            {uniqueYears?.map((data,index) => (
                                 <li key={index}><a class="dropdown-item" href="#" onClick={() => setSelectedYear(data)}>{data}</a></li>
                            ))}
                        </ul>
                    </div>
                    <div class="tab-content" id="pills-tabContent">
                        <div className='row gy-4'>
                            {subCategories 
                                && subCategories
                                .filter((item) => {
                                    const categoryMatch = selectedCategory === 'All' || item.category?.title === selectedCategory;
                                    const yearMatch = item.year.includes(selectedYear); 
                                    return categoryMatch && yearMatch;
                                })
                                .map(({ image ,title ,date, id}, index) => (
                                    <div key={index} className="col-12 col-md-6 col-lg-4 cursor-pointer position-relative">
                                        <Link to={`/gallery/sub-gallery/${id}`}>
                                            <img src={image} alt="Casino Premises" />
                                            {galleries && (
                                                <div className='overlay-hover-prime-v2'>
                                                  <span className='fs-18 text-black text-center'>
                                                    <p className='fs-18'>{title}</p>
                                                    {galleries.filter(data => data?.sub_category?.id == id).length} Photo(s)
                                                    <p className='fs-16'>{date}</p>
                                                  </span>
                                              </div>
                                            )}
                                        </Link>
                                    </div>  
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default SubGallery