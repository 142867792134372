import React, { useState } from 'react'
import { ReactSVG } from 'react-svg';
import { settings2 } from '../components/Slick';
import exploreCitiesWhite from '../../assets/storage/homescreen/icons/exploreCities.svg';
import summerActivities from '../../assets/storage/homescreen/icons/summerActivites.svg';
import winterActivites from '../../assets/storage/homescreen/icons/winterActivites.svg';
import shopping from '../../assets/storage/homescreen/icons/shopping.svg';
import artCulture from '../../assets/storage/homescreen/icons/artCulture.svg';
import exploreCities from '../../assets/storage/homescreen/icons/Explore_Cities_Dark.svg'
import summerActivitiesWhite from '../../assets/storage/homescreen/icons/summerActivitiesWhite.svg';
import winterActivitesWhite from '../../assets/storage/homescreen/icons/winterActivitiesWhite.svg';
import shoppingWhite from '../../assets/storage/homescreen/icons/shoppingWhite.svg';
import artCultureWhite from '../../assets/storage/homescreen/icons/artCultureWhite.svg';
import locationIcon from "../../assets/storage/homescreen/icons/location.svg";
import WorldWide from '../../assets/storage/homescreen/icons/General.svg';
import requirement from '../../assets/storage/homescreen/icons/requirement.svg';
import red_cross from '../../assets/storage/homescreen/icons/red-cross.png';
import internal_security from '../../assets/storage/homescreen/icons/internal-security-1.png';
import general_security from '../../assets/storage/homescreen/icons/general-sec.png';
import beirut_airport from '../../assets/storage/homescreen/icons/beirut-airport.png';
import cust_prot from '../../assets/storage/homescreen/icons/cust-prot.png';
import calendar from '../../assets/storage/homescreen/icons/calendar.svg';
import Slider from 'react-slick';
import StripTags from '../components/StripTags';
import { useEffect } from 'react';
import axios from 'axios';
import apiUrl from '../../config';
import { Link } from 'react-router-dom';

const Travel = () => {


    const [tourism, setTourism] = useState([]);
    const [categories,setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('Explore Cities')
    const [general,setGeneral] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [holidayFix, setHolidayFix] = useState([]);
    const [religiousHoliday, setReligiousHoliday] = useState([]);
    const [location,setLocation] = useState({});

    const categoryIcons = [
        { category: 'Explore Cities', activeIcon: exploreCities, inactiveIcon: exploreCitiesWhite },
        { category: 'Summer Activities', activeIcon: summerActivities, inactiveIcon: summerActivitiesWhite },
        { category: 'Winter Activities', activeIcon: winterActivites, inactiveIcon: winterActivitesWhite },
        { category: 'Shopping', activeIcon: shopping, inactiveIcon: shoppingWhite },
        { category: 'Art Culture', activeIcon: artCulture, inactiveIcon: artCultureWhite },
    ];
      
    const [activeTab, setActiveTab] = useState(categoryIcons[0].category.replace(/\s+/g, ''));
    const handleTab = (tab) => {
        setActiveTab(tab)
    };

    function truncate(string,number)  {
        return string?.length > number ? string.substring(0,number-1)+ "..." : string
    }
    

    useEffect(()=> {
        const fetchTourism = async () => {
            const res = await axios.get(`${apiUrl}/api/travel/tourism`);
            setTourism(res.data.tourism);
            setCategories(res.data.categories);
        } 
        fetchTourism();
    },[])


    useEffect(() => {
        const fetchPracticalInfo = async () => {
            const res = await axios.get(`${apiUrl}/api/travel/info`);
            setGeneral(res.data.general);
            setRequirements(res.data.requirements);
            setHolidayFix(res.data.holiday_fix_dates);
            setReligiousHoliday(res.data.holiday_religious);
            setLocation(res.data.location)
        } 
        fetchPracticalInfo();
    },[])
    
    function MySlider({settings}) {
        return (
          <Slider {...settings}>
            {tourism
            .filter((data) => {
            const category = data.category?.title.toLowerCase();
            const selected = selectedCategory.toLowerCase();
            const includesCategory = category.includes(selected);
            return includesCategory;
            })
            .map((data, index) => (
            <div className='travel-card picture-wrapper padding-top-100' key={index}>
                <img src={data.image} alt="" />
                <div className='w-75'>
                <div className='d-flex align-items-center justify-content-between'>
                    <span className='fs-20 fw-bold text-white text-uppercase'>{data.title}</span>
                    <span className=''><ReactSVG src={location} className="w-5" /></span>
                </div>
                <p className='text-white'><StripTags policyText={truncate(data?.location,100)} /></p>
                <p className='text-white fs-14'><Link className='text-decoration-none text-white' to={`/travel-to-lebanon/${data?.id}`}>Read More</Link></p>
                </div>
                <div className='overflow-black'></div>
            </div>
            ))}
          </Slider>
        );
      }
    
  return (
    <> 
    <div className='travel'>
        <div className="navs py-5">
            <div className='w-60 mx-auto p-4 text-center'>
                <ul class="nav nav-pills nav-fill justify-content-center flex-md-nowrap mb-3 w-100" id="pills-tab" role="tablist">
                    <li class="nav-item w-100" role="presentation">
                        <button class="nav-link fs-18 active" id="pills-customer-service-tab" data-bs-toggle="pill" data-bs-target="#pills-customer-service" type="button" role="tab" aria-controls="pills-customer-service" aria-selected="true">Tourism & Leisure</button>
                    </li>
                    <li class="nav-item w-100" role="presentation">
                        <button class="nav-link fs-18" id="pills-contact-directory-tab" data-bs-toggle="pill" data-bs-target="#pills-contact-directory" type="button" role="tab" aria-controls="pills-contact-directory" aria-selected="false">Practical Info</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active w-100" id="pills-customer-service" role="tabpanel" aria-labelledby="pills-customer-service-tab">
            <section id="travel-v2">
                <div className='my-4'>
                    <div class="d-flex flex-lg-row flex-column justify-content-between">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{height: 'fit-content'}}>
                            {categoryIcons.map((item, index) => (
                                <button
                                key={index}
                                className={`nav-link ${activeTab === item.category.replace(/\s+/g, '') ? 'active' : ''}`}
                                onClick={() => handleTab(item.category.replace(/\s+/g, ''))}
                                id={`v-pills-${item.category.replace(/\s+/g, '')}-tab`}
                                data-bs-toggle="pill"
                                data-bs-target={`#v-pills-${item.category.replace(/\s+/g, '')}`}
                                type="button"
                                role="tab"
                                aria-controls={`v-pills-${item.category.replace(/\s+/g, '')}`}
                                aria-selected={activeTab === item.category.replace(/\s+/g, '')}
                                >
                                <div className="d-flex align-items-center g-1" onClick={() => setSelectedCategory(item.category)}>
                                    <ReactSVG src={activeTab === item.category.replace(/\s+/g, '') ? item.activeIcon : item.inactiveIcon} className="travel__icons" />
                                    <span className="w-50 fs-18 fw-bold">{item.category}</span>
                                </div>
                                </button>
                            ))}
                            </div>
                      
                        <div class="tab-content" id="v-pills-tabContent">
                                {categoryIcons.map((item, index) => (
                                        <div
                                        key={index}
                                        className={`tab-pane fade ${activeTab === item.category.replace(/\s+/g, '') ? 'show active' : ''}`} style={{transition:'opacity ease'}}
                                        id={`v-pills-${item.category.replace(/\s+/g, '')}`}
                                        role="tabpanel"
                                        aria-labelledby={`v-pills-${item.category.replace(/\s+/g, '')}-tab`}
                                        >
                                        <div className='tourism'>   
                                            <div>
                                            {categories && categories?.filter((category) => category.title === item.category)
                                                    .map((data,id)=> (
                                                        <div key={id}>
                                                            
                                                            <p className='text-second py-1 my-3 w-75 width-75 fs-20 text'>
                                                                <StripTags policyText={data?.description} />
                                                            </p>
                                                        </div>
                                                    ))}
                                                
                                                <div className='travel-gallery'>
                                                    <div className='slider-container mb-2'>
                                                        <MySlider settings={settings2} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="tab-pane fade w-100" id="pills-contact-directory" role="tabpanel" aria-labelledby="pills-contact-directory-tab">
            <div className='container'>
                <div className='row my-5'>
                    <div className='col-md-4 col-12'>
                        <img className='w-auto h-10' src={WorldWide} alt="" />
                        <p className='fs-23 fw-bold text-prime text-uppercase my-2'>General</p>
                        <div className='fs-18'>
                            <p className='my-3'>Capital : Beirut</p>
                            <p className='my-3'>Country Code : +961</p>
                            <p className='my-3'>Time: GMT+2</p>
                            {general?.map((data,index) => (
                                <div key={index}>
                                    <p className='my-3 w-75'><StripTags policyText={data?.text} /></p>
                                </div>
                            ))}
                        </div>
                        <div className='d-flex align-items-center g-1 my-2'>
                            <img className='w-5' src={locationIcon} alt="" />
                            <Link className='fs-23 fw-bold text-prime' to={location?.link}>View Location On Map</Link>
                        </div>
                    </div>
                    <div className='col-md-4 col-12'>
                        <img className='w-auto h-10' src={requirement} alt="" />
                        <p className='fs-23 fw-bold text-prime text-uppercase my-2'>Entry Requirements</p>
                        <div className='fs-18 my-2 w-75'>
                            {requirements?.map((data,index) => (
                                <div key={index}>
                                    <p className='my-3'><StripTags policyText={data?.text} /></p>
                                </div>
                            ))}
                        </div>
                        <p className='fs-23 fw-bold text-prime text-uppercase my-2'>Useful Numbers</p>
                        <div className='useful-numbers'>
                            <div className='d-flex align-items-center fs-18 mb-3 g-1'>
                                <img class="w-5" src={beirut_airport} alt='' />
                                <span><b>150 / +961 1 628000:</b> Rafic Hariri International Airport</span>
                            </div>
                            <div className='d-flex align-items-center fs-18 mb-3 g-1'>
                                <img class="w-5" src={internal_security} alt='' />
                                <span><b>112:</b> Internal Security Forces</span>
                            </div>
                            <div className='d-flex align-items-center fs-18 mb-3 g-1'>
                                <img class="w-5" src={red_cross} alt='' />
                                <span><b>140:</b> Lebanese Red Cross</span>
                            </div>
                            <div className='d-flex align-items-center fs-18 mb-3 g-1'>
                                <img class="w-5" src={general_security} alt='' />
                                <span><b>1717:</b> General Security</span>
                            </div>
                            <div className='d-flex align-items-center fs-18 mb-3 g-1'>
                                <img class="w-5" src={cust_prot} alt='' />
                                <span><b>1739:</b> Consumer Protection</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-12 mb-3'>
                        <img className='w-auto h-10' src={calendar} alt="" />
                        <p className='fs-23 fw-bold text-prime text-uppercase my-2'>Public Holidays</p>
                        <div className='fs-18 my-2 w-75'>
                            <p className='my-3 fs-18'>Banks, government offices and schools are closed on holidays, but it is often possible to find shops and restaurants open for business.</p>
                        </div>
                    
                        <p className='fw-bold fs-20 text-second my-2'>Holidays with fixed dates</p>
                        {holidayFix && holidayFix?.map((data,index) => (
                            <div key={index} className='d-flex align-items-center justify-content-between fs-18 mb-3'>
                                <span>{data?.title}</span>
                                <span><StripTags policyText={data?.text} /></span>
                            </div>
                        ))}
                        <p className='fw-bold fs-20 text-second mb-3'>Religious Holidays with variable dates</p>
                            {religiousHoliday && religiousHoliday?.map((data,index) => (
                                <div key={index} className='d-flex align-items-center justify-content-between fs-18 mb-3'>
                                <span>{data?.title}</span>
                                {data?.text && (
                                    <span><StripTags policyText={data?.text} /></span>
                                )}
                            </div>
                            ))}
                        <p className='fs-18'>*Casino Du Liban opens 365 day a year except on Catholic Good Friday.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
  )
}

export default Travel