import React, { useEffect, useState } from 'react'
import { Accordion,AccordionSummary,AccordionDetails,Accordion_v2,AccordionSummary_v2,AccordionDetails_v2, AccordionTypography_v2 } from '../components/Accordion';
import Typography from '@mui/material/Typography';
import clock from '../../assets/storage/homescreen/icons/clock.svg'
import axios from 'axios';
import StripTags from '../components/StripTags';
import apiUrl from '../../config';
const Rewards = () => {

    const [expanded, setExpanded] = useState('panel1');
    const [expanded2, setExpanded2] = useState('panel2');
    const [levels, setLevels] = useState([]);
    const [benefits, setBenefits] = useState([]);
    const [benefitCategory,setBenefitCategory] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChange_v2 = (panel) => (event, newExpanded) => {
        setExpanded2(newExpanded ? panel : false);
    };

    useEffect(() => {
        const fetchLevels = async () => {
            const res = await axios.get(`${apiUrl}/api/cdl-rewards/levels`);
            setLevels(res.data);
        }
        fetchLevels()

        const fetchBenefits = async () => {
            const res = await axios.get(`${apiUrl}/api/cdl-rewards/benefits`);
            setBenefits(res.data.benefits);
            setBenefitCategory(res.data.categories)
        }
        fetchBenefits()

        const fetchFaqs = async () => {
            const res = await axios.get(`${apiUrl}/api/cdl-rewards/faq`);
            setFaqs(res.data);
        }
        fetchFaqs()
    },[])

  return (
    <>
        <div className='navs-v2'>
            <div className='w-60 mx-auto px-4 pt-5'>
                <div className='main-title '><span className='h3'>Card Levels & Benefits</span></div>
                <div className='my-5'>
                    <div className='row'>
                        {levels && levels?.map(({title,description,image},id) => (
                            <>
                                <div key={id} className='col-6 col-md-3 my-2'>
                                    <div className="picture-wrapper padding-top-66">
                                        <img src={image} alt="Gold" />
                                    </div>
                                    <p className='text-center fs-20 fw-bold text-blue-heavy mt-3'>{title}</p>
                                    <p className='text-center fs-20 fw-bold text-blue-heavy'><StripTags policyText={description} /></p>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='b-prime br-5 my-5'>
                    
                        {benefitCategory && benefitCategory?.map(({title},id) => (
                            <>
                            <div className='accordion'>
                            <Accordion_v2 expanded={expanded === id} onChange={handleChange(id)}>
                                <AccordionSummary_v2 aria-controls="panel1d-content" id="panel1d-header">
                                <AccordionTypography_v2>{title}</AccordionTypography_v2>
                                </AccordionSummary_v2>
                                {benefits && benefits?.filter((data) => data?.category?.title === title).map((data,index) => (
                                     <AccordionDetails_v2>
                                     <Typography>
                                         <div key={index} className='d-flex flex-column flex-sm-row justify-content-between align-items-center w-75'> 
                                             <p className='fs-18 w-25'><StripTags policyText={data?.description} /></p>
                                             <div className='d-flex justify-content-center align-items-center gap-circles-10 my-3 my-md-0'>
                                                 {data?.cdl_levels?.map((level,id) => (
                                                     <>
                                                         <div className={`${level?.pivot?.cdl_level_id === 1 ? 'eclipse-gold' : 'd-none' }`}></div>
                                                         <div className={`${level?.pivot?.cdl_level_id === 2 ? 'eclipse-plat' : 'd-none' }`}></div>
                                                         <div className={`${level?.pivot?.cdl_level_id === 3 ? 'eclipse-diamond' : 'd-none' }`}></div>
                                                         <div className={`${level?.pivot?.cdl_level_id === 4 ? 'eclipse-cedars' : 'd-none' }`}></div>
                                                     </>
                                                 ))}
                                             </div> 
                                         </div>
                                     </Typography>
                                     </AccordionDetails_v2>
                                ))}
                            </Accordion_v2>  
                            </div>
                            </>
                        ))}  
                </div>   
            </div>
            <div className='w-60 mx-auto p-4 accordion'>
                <div className='main-title '><span className='h3'>CDL Rewards</span></div>
                <div className='w-75'>
                    {faqs && faqs?.map(({title,description},id) => (
                        <div className='mt-4'>
                          <Accordion expanded={expanded2 === id} onChange={handleChange_v2(id)}>
                              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                              <Typography>{title}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                              <Typography>
                                  <StripTags policyText={description} />
                              </Typography>
                              </AccordionDetails>
                          </Accordion>  
                      </div>
                    ))}
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-center mb-md-4 mb-5'>
                <div className='d-flex align-items-center justify-content-center'>
                    <img className='w-auto' src={clock} alt="clock" />
                    <div className='d-flex ps-3 align-items-center'>
                        <span className='fw-bold text-prime fs-18 me-2'>Opening Hours</span>
                        <span className='text-prime fs-18'>4:00 pm - 4:00 am</span>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Rewards