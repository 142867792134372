import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import apiUrl from '../../config';
const Gallery = () => {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            const res = await axios.get(`${apiUrl}/api/gallery`)
            setCategories(res.data)
        }
        fetchCategories()
    },[])


  return (
    <>
        <section id="gallery">
            <div className='container my-5'>
                <div className='row gy-4'>
                    {categories && categories?.map(({title,image,id},index) => (
                        <div key={index} className='col-12 col-md-6 col-lg-4 cursor-pointer'>
                            <Link to={`/gallery/${id}`}>
                                <div className='picture-wrapper padding-top-85'>
                                    <div className='overlay-hover-prime'></div>
                                    <div className="pos-gallery-title"><span className='h3 text-white fw-bold'>{title}</span></div>
                                    <img src={image} alt="Casino Premises" />
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    </>
  )
}

export default Gallery