import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import apiUrl from '../../config';
import StripTags from '../components/StripTags'

const TravelDetail = () => {

    const [detail,setDetail] = useState({});
    const {id} = useParams();

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
    
    useEffect(() => {
        const fetchDetail = async () => {
           const res = await axios.get(`${apiUrl}/api/travel/tourism/${id}`);
           setDetail(res.data);
           console.log(res.data);
        }
        fetchDetail();
        scrollToTop();
    },[id])
   
  return (
    <>
        <div className='w-60 mx-auto p-4'>
            <div className='main-title '><span className='h3'>{detail?.title}</span></div>
                <>
                    <div className='main-description mb-5'>
                        <p className='text-center fs-18 w-75'>
                            <StripTags policyText={detail?.location} />
                        </p>
                    </div>
                
                    <div className='picture-wrapper padding-top-40 my-4'>
                        <img src={detail?.image} alt='banner-table' />
                    </div>
                </>
        </div>
        
    </>
  )
}

export default TravelDetail