import React, { useEffect, useState } from 'react'
import { Accordion,AccordionSummary,AccordionDetails } from '../components/Accordion';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import StripTags from '../components/StripTags';
import apiUrl from '../../config';
const Faq = () => {

    const [expanded, setExpanded] = useState('panel1');

    const [faq,setFaq] = useState([]);
    const [category,setCategory] = useState([]);
    const [activeCategory,setActiveCategory] = useState(1);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleCategory = (id) => {
        setActiveCategory(id);
    }

    
    useEffect(() => {
        const fetchFaq = async () => {
            const res = await axios.get(`${apiUrl}/api/faqs`);
            setFaq(res.data.faqs);
            setCategory(res.data.category);
        }
        fetchFaq()
    },[])



  return (
    <>
        <div className='navs-v2'>
            <div className='w-60 mx-auto p-4'>
                <div className='main-title '><span className='h3'>FAQ</span></div>
                <div class="d-flex flex-column flex-md-row align-items-start">
                <div class="d-flex flex-column flex-md-row align-items-start">
                <div class="nav flex-column nav-pills faq-list" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ maxWidth: '310px' }}>
                    {category && category.map(({ title, id }, index) => (
                    <button
                        key={index}
                        onClick={() => handleCategory(id)}
                        class={`nav-link ${index === 0 ? 'active' : ''}`}
                        id={`v-${index}-tab`}
                        data-bs-toggle="pill"
                        data-bs-target={`#v-${index}`}
                        type="button"
                        role="tab"
                        aria-controls={`v-${index}`}
                        aria-selected={index === 0}
                    >
                        {title}
                    </button>
                    ))}
                </div>
            <div class="tab-content" id="v-pills-tabContent" className='w-100'>
                    {category &&
                        category?.map(({ id },index) => {
                            const filteredFAQs = faq.filter((faq) => faq.faq_category_id === id);
                            const isCurrentCategory = activeCategory == id;
                            return (
                            <div
                                key={index}
                                class={`tab-pane fade mt-5 ms-md-5 ${isCurrentCategory ? 'show active' : ''}`}
                                id={`v-${index}`}
                                role="tabpanel"
                                aria-labelledby={`v-${index}-tab`}
                            >
                                {isCurrentCategory &&
                                    filteredFAQs.map(({ title, description }, index) => (
                                    <div className="mt-4" key={index}>
                                        <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                                        <AccordionSummary aria-controls={index} id={index}>
                                            <Typography>{title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <StripTags policyText={description} />
                                            </Typography>
                                        </AccordionDetails>
                                        </Accordion>
                                    </div>
                                    ))}
                            </div>
                            );
                    })}
                </div>
                </div>

        </div>
    </div>
</div>

        
    </>
  )
}

export default Faq