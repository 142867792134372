import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import axios from 'axios';
import StripTags from '../components/StripTags';
import apiUrl from '../../config';

const Policy = () => {

    const [policy, setPolicy] = useState({});
    const [terms, setTerms] = useState([]);
    
    useEffect(() => {
        const fetchPolicy = async () => {
            const res = await axios.get(`${apiUrl}/api/policy`);
            setPolicy(res.data);
        }

        const fetchTerms = async () => {
            const res = await axios.get(`${apiUrl}/api/terms`);
            setTerms(res.data);
        }
        fetchPolicy()
        fetchTerms()
    },[])

  return (
    <>
        <div className='container'>
            <div className='navs pt-5'>
                <div className='text-center w-60 mx-auto'>
                    <ul class="nav nav-pills nav-fill justify-content-center flex-md-nowrap mb-3 w-100" id="pills-tab" role="tablist">
                        <li class="nav-item w-100" role="presentation">
                            <button class="nav-link fs-18 active" id="pills-customer-service-tab" data-bs-toggle="pill" data-bs-target="#pills-customer-service" type="button" role="tab" aria-controls="pills-customer-service" aria-selected="true">Privacy Policy</button>
                        </li>
                        <li class="nav-item w-100" role="presentation">
                            <button class="nav-link fs-18" id="pills-contact-directory-tab" data-bs-toggle="pill" data-bs-target="#pills-contact-directory" type="button" role="tab" aria-controls="pills-contact-directory" aria-selected="false">Terms & Conditions</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='navs-v2'>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active w-100" id="pills-customer-service" role="tabpanel" aria-labelledby="pills-customer-service-tab">
                        <div className='w-60 mx-auto p-4'>
                            <div className='main-title '><span className='h3'>Privacy Policy</span></div>
                            {policy && (
                                <>
                                    <div className='main-description'>
                                        <p className='text-center text-second mb-5 fs-18 w-100'>
                                           <StripTags policyText={policy.text} />
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div class="tab-pane fade w-100" id="pills-contact-directory" role="tabpanel" aria-labelledby="pills-contact-directory-tab">
                        <div className=''>
                            <div className='w-60 mx-auto p-4'>
                                <div className='main-title '><span className='h3'>Terms & Conditions</span></div>
                                <div className='main-description'>
                                    <p className='text-center text-second fs-18 w-100'>
                                        These Terms and Conditions (the “Terms”) will govern your use of the CDL software applications, including all content provided by CDL through the CDL software applications, as well as any and all websites, web services API and other online services collectively (“CDL” or “Service(s)”); by accessing or using this Service, you, the user(s) of the Service (“You” or the “User(s)”) agree to be bound by these Terms and CDL Privacy Policy, which can be found on Our Site at (http address) and which are incorporated by reference into these Terms. CDL offers you content services through a licensed application (the “CDL App”) for mobile devices.
                                        Certain features of the Service may be subject to additional guidelines, terms or rules, which will be posted in or on the Service in connection with such features. All such additional terms, guidelines and rules are incorporated by reference into these Terms.
                                        CDL is the official Casino Du Liban mobile application.
                                    </p>
                                </div>
                               
                                <div className="d-flex align-items-start flex-column flex-sm-row">
                                    <div className="nav flex-column nav-pills policy-pill" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ width: '500px' }}>
                                        {terms && terms.map(({ title }, index) => (
                                        <button
                                            key={index}
                                            className={`nav-link ${index === 0 ? 'active' : ''}`}
                                            id={`v-${index}-tab`}
                                            data-bs-toggle="pill"
                                            data-bs-target={`#v-${index}`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`v-${index}`}
                                            aria-selected={index === 0}
                                        >
                                            {title}
                                        </button>
                                        ))}
                                    </div>
                                    <div className="tab-content ms-4" id="v-pills-tabContent" style={{ width: '100%' }}>
                                        {terms && terms.map(({ description }, index) => (
                                        <div
                                            key={index}
                                            className={`tab-pane fade mt-5 ms-0 ms-sm-5 ${index === 0 ? 'show active' : ''}`}
                                            id={`v-${index}`}
                                            role="tabpanel"
                                            aria-labelledby={`v-${index}-tab`}
                                            tabIndex="0"
                                        >
                                            <p className="text-second fs-18">
                                                <StripTags policyText={description} />
                                            </p>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
   

    </>
  )
}

export default Policy