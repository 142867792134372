import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import DatePicker from "react-datepicker";
import calendar from '../../assets/storage/homescreen/icons/calendar.svg'
import apiUrl from '../../config';
import StripTags from '../components/StripTags'
const Contact = () => {

    const [startDate, setStartDate] = useState(new Date());
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [address, setAddress] = useState({});
    const [directories, setDirectories] = useState([]);
    const [countries, setCountries] = useState([]);

    const [formData, setFormData] = useState({
        complaint: '',
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        nationality: '',
        description: '',
        when: '',
        where: '',
    })

    const [formErrors, setFormErrors] = useState({
        complaint: '',
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        nationality: '',
        description: '',
        when: '',
        where: '',
    });
  
    const onChangeV1 = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.getAttribute('name')]: e.target.getAttribute('value'),
        }));
    };

    const onChangeV2 = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
          when : moment(startDate).format('YYYY-MM-DD'),
        }));
    };
    
    useEffect(() => {
        const fetchContact = async () => {
            const res = await axios.get(`${apiUrl}/api/contacts`);
            setAddress(res.data.address);
            setDirectories(res.data.directories);
        } 
        const fetchCountries = async () => {
            const res = await axios.get(`${apiUrl}/api/countries`);
            setCountries(res.data);
            console.log(res.data);
        } 
        fetchContact();
        fetchCountries();
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault()
        let isValid = true;


        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === '') {
              isValid = false;
              setFormErrors((prevErrors) => ({
                ...prevErrors,
                [key]: `${key.replace('_', ' ')} is required.`,
              }));
            } else {
              setFormErrors((prevErrors) => ({
                ...prevErrors,
                [key]: '',
              }));
            }
        });

        if (!isValid) {
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/api/submit`, formData);

            setFormData({
                complaint: '',
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                nationality: '',
                description: '',
                when: '',
                where: '',
                });
                setIsSubmitted(true);

            } catch (error) {
            console.error(error);
            }

             
    }

  return (
    <>
        <div className=''>
            <div className='w-60 mx-auto p-4'>
                <div className='navs py-5'>
                    <ul class="nav nav-pills nav-fill justify-content-center flex-md-nowrap w-100 mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item w-100 fs-20 br-prime" role="presentation">
                            <button class="nav-link active" id="pills-customer-service-tab" data-bs-toggle="pill" data-bs-target="#pills-customer-service" type="button" role="tab" aria-controls="pills-customer-service" aria-selected="true">Customer Service</button>
                        </li>
                        <li class="nav-item w-100 fs-20 bl-prime" role="presentation">
                            <button class="nav-link" id="pills-contact-directory-tab" data-bs-toggle="pill" data-bs-target="#pills-contact-directory" type="button" role="tab" aria-controls="pills-contact-directory" aria-selected="false">Contact Directory</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-customer-service" role="tabpanel" aria-labelledby="pills-customer-service-tab">
                            <div className='customer-service'>
                                <div className='main-title'><span className='h3'>Your Satisfaction Counts</span></div>
                                <div className='main-description mb-6'>
                                    <p className='text-center fs-18'>
                                        Casino du Liban cares about offering its customers the best quality service.<br />
                                        Should you have an inquiry or suggestion to make or if you experienced a regrettable incident, please fill the<br />
                                        form below to convey your message. A member of the Customer Service Unit will get back to you shortly.
                                    </p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className='row my-4'>
                                        <div className='col-sm-6'>
                                            <div className='feedback'>
                                                <h4 className='text-prime fw-bold fs-23 text-uppercase'>Feedback Type *</h4>
                                                <div class="dropdown v2 mt-4">
                                                    <button class="btn btn-secondary dropdown-toggle w-100 text-uppercase fs-18 text-start" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {formData.complaint || 'Select Type'}
                                                    </button>
                                                    <ul class="dropdown-menu transform-none w-100" aria-labelledby="dropdownMenuButton1">
                                                        <li><a class="dropdown-item" name="complaint" value="Complaint" onClick={onChangeV1}>Complaint</a></li>
                                                        <li><a class="dropdown-item" name="complaint" value="Lost and Found" onClick={onChangeV1}>Lost and Found</a></li>
                                                        <li><a class="dropdown-item" name="complaint" value="Feedback" onClick={onChangeV1}>Feedback</a></li>
                                                        <li><a class="dropdown-item" name="complaint" value="Something Else" onClick={onChangeV1}>Something Else</a></li>
                                                    </ul>
                                                    {formErrors.complaint && <span className='error'>{formErrors.complaint}</span>}
                                                </div>
                                            </div>

                                            <div className='personal-info mt-5'>
                                                <h4 className='text-prime fw-bold fs-23 text-uppercase'>Personal Info</h4>
                                                <div>
                                                    <label className='fs-20 fw-boldlighter my-3'>First Name *</label><br />
                                                    <input type='text' name="first_name" value={formData.first_name} onChange={onChangeV2} className='b-prime w-100' />
                                                    {formErrors.first_name && <span className='error'>{formErrors.first_name}</span>}
                                                </div>
                                                <div>
                                                    <label className='fs-20 fw-boldlighter my-3'>Last Name *</label><br />
                                                    <input type='text' name="last_name" value={formData.last_name} onChange={onChangeV2} className='b-prime w-100' />
                                                    {formErrors.last_name && <span className='error'>{formErrors.last_name}</span>}
                                                </div>
                                                <div>
                                                    <label className='fs-20 fw-boldlighter my-3'>Mobile *</label><br />
                                                    <input type='text' name="mobile" value={formData.mobile} onChange={onChangeV2} className='b-prime w-100' />
                                                    {formErrors.mobile && <span className='error'>{formErrors.mobile}</span>}
                                                </div>
                                                <div>
                                                    <label className='fs-20 fw-boldlighter my-3'>Email *</label><br />
                                                    <input type='email' name="email" value={formData.email} onChange={onChangeV2} className="b-prime w-100" />
                                                    {formErrors.email && <span className='error'>{formErrors.email}</span>}
                                                </div>
                                                <div>
                                                    <label className='fs-20 fw-boldlighter my-3'>Nationality *</label>
                                                    <div class="dropdown v2">
                                                        <button class="btn btn-secondary dropdown-toggle text-uppercase w-100 fs-16 text-start"  type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {formData.nationality || 'Select Your Nationality'}
                                                        </button>
                                                        <ul class="dropdown-menu countries-list transform-none w-100" aria-labelledby="dropdownMenuButton1">
                                                            {countries?.map(country => (
                                                                <li><a class="dropdown-item" name="nationality" value={country?.name} onClick={onChangeV1} >{country?.name}</a></li>
                                                            ))}
                                                        </ul>
                                                        {formErrors.nationality && <span className='error'>{formErrors.nationality}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='description'>
                                                <h4 className='text-prime fs-23 fw-bold text-uppercase my-4 my-sm-0'>Description *</h4>
                                                <textarea rows={3} cols={41} name="description" onChange={onChangeV2} className="b-prime mt-4  w-100" placeholder='Write your message here ...' />
                                                {formErrors.description && <span className='error'>{formErrors.description}</span>}
                                            </div>
                                            <div className='date'>
                                                <span className='text-prime fw-bold d-inline-block mb-3 mt-4 fs-23 text-uppercase'>When *</span>
                                                <div className='b-prime w-100 d-flex align-items-center justify-content-between'>
                                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                                    <img src={calendar} alt="calendar" style={{ width: '21px'}} />
                                                </div>
                                                {formErrors.startDate && <span className='error'>{formErrors.startDate}</span>}
                                            </div>

                                            <div className='where'>
                                                <span className='text-prime fw-bold fs-23 d-inline-block mb-3 mt-3  text-uppercase'>Where *</span>
                                                <input type='text' name="where" value={formData.where} onChange={onChangeV2} className="b-prime w-100" />
                                                {formErrors.where && <span className='error'>{formErrors.where}</span>}
                                            </div>

                                            <button type='submit' className='btnv2-prime mt-3'>Submit</button>
                                            <h4 className='text-prime fw-bold fs-23 text-uppercas mt-4'>* Required fields</h4>
                                            {isSubmitted && <div className='text-prime fw-bold'>Submitted Successfully</div>}
                                        </div>     
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-contact-directory" role="tabpanel" aria-labelledby="pills-contact-directory-tab">
                            <div className='row my-5'>
                                <div className='col-lg-5 col-sm-6 my-2 my-lg-0'>
                                    <span className='fw-bold fs-20'>Address</span>
                                    <div className='mt-3'>
                                        <span className='text-uppercase text-prime fs-23 fw-bold'>Casino du liban</span>
                                        {address && (
                                            <>
                                                <p className='text-heavy my-3 fs-18'>{address?.location}</p>
                                                <p className='text-heavy my-3 fs-18'>P.O.BOX : <b className='text-black'>{address?.p_o_box}</b></p>
                                                <p className='text-heavy my-3 fs-18'>Phone : <b className='text-black'>{address?.phone}</b></p>
                                                <p className='text-heavy my-3 fs-18'>Fax : <b className='text-black'>{address?.fax}</b></p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-7 col-12'>
                                    <iframe className='location' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13228.514200332078!2d35.6404893!3d34.0149111!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f413a3780a857%3A0xc69697254ed6af85!2z2YPYp9iy2YrZhtmIINmE2KjZhtin2YY!5e0!3m2!1sar!2slb!4v1681326076305!5m2!1sar!2slb" height="210" style={{border: '1px solid #b0a06c'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>

                            <div className='my-1'>
                                <span className='fw-bold'>Contacts</span>
                                <div className='row my-4'>
                                    {directories && directories?.map((directory,id) => (
                                        <div className='col-12 col-md-6' key={id}>
                                            <div className='b-prime br-5 my-4' style={{height:'230px'}}>
                                                <div className='bg-prime br-btr-4 text-center py-2'>
                                                    <span className='text-uppercase fs-20 text-white fw-bold'>{directory?.title}</span>
                                                </div>
                                                <div className='pt-4 pb-5 px-5'>
                                                    <div className='d-flex flex-wrap fs-18 fw-bold'><StripTags policyText={directory?.name} /></div>
                                                    <p className='text-heavy fw-bold fs-18 my-1'>Phone : <b className='text-black'>{directory?.phone}</b></p>
                                                    <p className='text-heavy fw-bold fs-18 my-1'>Email : <b className='text-black'>{directory?.email}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Contact