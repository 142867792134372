import React, { useEffect } from 'react'
import searchIcon from '../../assets/storage/homescreen/icons/Search-Icon.svg';
import clock from '../../assets/storage/homescreen/icons/clock.svg';
import axios from 'axios';
import { useState } from 'react';
import StripTags from '../components/StripTags';
import apiUrl from '../../config';
import { Link } from 'react-router-dom';
import moment from 'moment';

const News = () => {

    const [news, setNews] = useState([]);
    const [category, setCategory] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [search, setSearch] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const uniqueYears = [...new Set(news?.map(({ date }) => moment(date).format('YYYY')))].sort();

    useEffect(()=> {
        const fetchNews = async () => {
            const res = await axios.get(`${apiUrl}/api/news`);
            setNews(res.data.news);
            setCategory(res.data.category);
        } 
        fetchNews();
    },[])
    
  return (
    <>
        <div className='news'>
            <div className="container my-5">
                <div className='d-flex align-items-center justify-content-between flex-column flex-md-row'>
                    <div className="d-flex flex-column flex-md-row  align-items-center g-1">
                        <div class="dropdown v2">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {selectedYear ? selectedYear : 'All Years'}
                            </button>
                            <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item" href="#" onClick={() => setSelectedYear('')}>All Years</a></li>
                                {uniqueYears && uniqueYears?.map((data, index) => (
                                    <li key={index}><a class="dropdown-item" href="#" onClick={() => setSelectedYear(data)}>{data}</a></li>
                                ))}
                            </ul>
                        </div>
                        <div class="ms-md-4">
                        <div className=''>
                            <ul class="nav nav-pills d-flex justify-content-center align-items-center" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class={`nav-link fs-18 ${selectedCategory === 'All' ? 'active' : ''}`} onClick={() => setSelectedCategory('All')} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">All</button>
                                    </li>
                                {category && category?.map(({title}, index) => (
                                    <li key={index} class="nav-item fs-18" role="presentation">
                                        <button class="nav-link" id={`pills-${index}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${index}`} type="button" role="tab" aria-controls={`pills-${index}`} aria-selected="false"  onClick={() => setSelectedCategory(title)}>{title}</button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="search-input mt-3 mt-md-0">
                      <img className="w-auto" src={searchIcon} alt="search" />
                      <input type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                </div>

                <div className="row my-5">
                {news &&
                    news
                        .filter((item) => {
                            const titleMatch = item.title.toLowerCase().includes(search.toLowerCase());
                            const dateMatch = item.date.includes(selectedYear);
                            const categoryMatch = selectedCategory === 'All' || item.category.title === selectedCategory; 
                            return titleMatch && dateMatch && categoryMatch;
                        })
                        .map(({ description, image, link, title, date, source,pdf}, index) => (
                        <div key={index} className="col-12 col-sm-6 col-lg-4 mb-5">
                            <div className="picture-wrapper padding-top-55">
                            <img src={image} alt="" />
                            </div>
                            <div className="news__info">
                            <h2 className="text-prime font-times fs-35">{title}</h2>
                            <div className="d-flex align-items-center gap-5 mt-2">
                                <div className="d-flex align-items-center">
                                    <Link to={link} className="ms-2 fs-16 w-100 text-prime">
                                        {source}
                                    </Link>
                                </div>
                                <div className="d-flex align-items-center">
                                    <img className="w-20" src={clock} alt="" />
                                    <span className="me-2 fs-16 w-100 text-prime">
                                        {date}
                                    </span>
                                </div>
                            </div>
                            <p className="text-second my-4 fs-20">
                                <StripTags policyText={description} />
                            </p>
                            </div>
                            {link ? (
                                <span className="link__news fs-16">
                                    <Link to={link}>READ MORE</Link>
                                </span>
                            ) : (
                                <span className="link__news fs-16">
                                    <Link to={pdf}>READ MORE</Link>
                                </span>
                            )}  
                        </div>
                        ))}
                </div>
               </div>
                  
        </div>
</>
  )
}

export default News