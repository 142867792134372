import React, { useEffect, useState } from 'react'
import { ReactSVG } from "react-svg";
import Slider from "react-slick";
import clock from '../../assets/storage/homescreen/icons/clock.svg'
import access from '../../assets/storage/homescreen/icons/group-301.svg'
import dress from '../../assets/storage/homescreen/icons/group-300.svg'
import cloch from '../../assets/storage/homescreen/icons/cloche.svg'
import axios from 'axios'
import StripTags from '../components/StripTags'
import apiUrl from '../../config'
import { Link } from '@mui/material'
import { useLocation } from 'react-router-dom'

const Dining = () => {

    const [dining, setDining] = useState([]);
    const [restaurant,setRestaurant] = useState('Mediterranean Restaurant'.replace(/\s+/g, '').toLowerCase());

    const location = useLocation();
    const searchTab = location?.search?.split('?tab=')[1];
    console.log(restaurant);
    useEffect(() => {
        const fetchDining = async () => {
            const res = await axios.get(`${apiUrl}/api/dining`)
            setDining(res.data) 
        }
        fetchDining()
    },[restaurant])

    useEffect(() => {
        const fetchDining = async () => {
            const res = await axios.get(`${apiUrl}/api/dining`)
            setDining(res.data) 
            if(searchTab == 'casino-restaurants') {
                setRestaurant('mediterraneanrestaurant')
            } else {
                setRestaurant(searchTab)
            }
   
        }
        fetchDining()
    },[searchTab])

    const settings = {
        infinite: true,
        dots: false,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        initialSlide: 0,
        arrows: true, 
        swipeToSlide: true,
    }
    

  return (
    <>
        <div className='dining'>
            <div className='navs py-5'>
                <div className='container p-4 text-center'>
                    <ul class="nav nav-pills nav-fill justify-content-center flex-md-nowrap mb-3 w-100 table-pill">
                        <li class="nav-item dropdown w-100 br-prime">
                            <a class={`nav-link dropdown-toggle fs-20 left text-capitalize ${searchTab === 'casino-restaurants' ? 'active' : ''}`}  href="#casino_restaurants" data-bs-toggle="dropdown">Casino Restaurants</a>
                            <ul class="dropdown-menu w-100 p-0 room">
                                {dining && dining?.filter(data => data.title !== "LA MARTINGALE RESTAURANT" && data.title !== "LA MARTINGALE TERRACE")
                                    .map((data,index) => (
                                        <li key={index}><a class={`dropdown-item text-center w-100 fs-18 text-capitalize ${data?.title?.replace(/\s+/g, '').toLowerCase() === restaurant ? 'active' : ''}`} onClick={() => setRestaurant(data?.title.replace(/\s+/g, '').toLowerCase())} data-bs-toggle="pill" aria-expanded="false" href="#casino_restaurants">{data?.title}</a></li>
                                ))}
                            </ul>
                        </li>
                        <li class="nav-item w-100 bl-prime br-prime">
                            <a class={`nav-link  w-100 fs-18  ${searchTab === 'la-martingale-restaurant' ? 'active show' : ''}`} data-bs-toggle="pill" href="#martingale_restaurant" onClick={() => setRestaurant('La Martingale Restaurant'.replace(/\s+/g, '').toLowerCase())}>La Martingale Restaurant</a>
                        </li>
                        <li class="nav-item w-100 bl-prime">
                            <a class={`nav-link right  w-100 fs-18 ${searchTab === 'la-martingale-terrase' ? 'active show' : ''}`} data-bs-toggle="pill" href="#martingale_terrace" onClick={() => setRestaurant('La Martingale Terrace'.replace(/\s+/g, '').toLowerCase())}>La Martingale Terrace</a>
                        </li>
                    </ul>
                </div>
             
                <div class="tab-content" id="pills-tabContent">
                   
                    <div
                        className={`tab-pane fade ${searchTab === 'casino-restaurants' || ['internationalrestingarea', "cercled'orrestaurant",'mediterraneanrestaurant'].includes(restaurant) ? 'show active' : ''} w-100`}
                        id="casino_restaurants"
                        role="tab"
                        aria-labelledby="pills-customer-service-tab"
                    >
                        <div className='w-60 mx-auto p-4'>
                            {dining && dining?.filter((data) => data?.title.replace(/\s+/g, '').toLowerCase() === restaurant).map((data,index) => (
                                 <>
                                 <div key={index} className='main-title-v2'><span className='h3'>{data?.title}</span></div>
                                 <div className='main-description'>
                                     <p className='text-center fs-18 w-75'>
                                       <StripTags policyText={data?.text} />
                                     </p>
                                 </div>

                                <div className='d-flex justify-content-center gap-sm-5 my-5'>
                                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                                        <img className='pt-4 width-25' src={clock} alt="clock" />
                                        <div className='d-flex flex-column ps-sm-3'>
                                            <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Opening Hours</span>
                                            <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={data?.opening_hour} /></span>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                                        <img className='pt-4 width-25' src={access} alt="clock" />
                                        <div className='d-flex flex-column mx-4 mx-sm-0 ps-sm-3'>
                                            <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Access</span>
                                            <span className='text-prime fs-18 text-center text-sm-start'>Membership Required</span>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                                        <img className='pt-4 width-25' src={dress} alt="clock" />
                                        <div className='d-flex flex-column ps-sm-3'>
                                            <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Dress Code</span>
                                            <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={data?.dress_code} /></span>
                                        </div>
                                    </div>
                                    {(data?.carte || data?.link) && (
                                        <div className='d-flex flex-column flex-sm-row align-items-center'>
                                            <img className='pt-4 width-25 ms-3 ms-sm-0' src={cloch} alt="clock" />
                                            <div className='d-flex flex-column ps-3'>
                                                <span className='fs-18 fw-bold text-center text-prime text-sm-start'>La Carte</span>
                                                <a className='text-prime fs-18 text-center text-sm-start text-decoration-none pointer' href={data?.carte ? data?.carte : data?.link}>A La Carte</a>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <Slider {...settings}>         
                                    {data?.images?.map((image, imageIndex) => (
                                        <div key={imageIndex} className='picture-wrapper padding-top-40 my-4'>
                                            <img src={image?.image_name} alt='banner-table' />
                                        </div>
                                    ))} 
                                </Slider>

                                 </>
                            ))}   
                    </div>
                </div>

                <div class={`tab-pane fade ${searchTab === 'la-martingale-restaurant' ? 'show active' : ''}  w-100`} id="martingale_restaurant" aria-labelledby="pills-about-tab">
                    {dining && dining?.filter((data) => data?.title.replace(/\s+/g, '').toLowerCase() === 'La Martingale Restaurant'.replace(/\s+/g, '').toLowerCase()).map((data,index) => (
                        <div key={index} className='w-60 mx-auto p-4'>
                            <div className='main-title-v2'><span className='h3'>{data?.title}</span></div>
                            <div className='main-description'>
                                <p className='text-center w-75 fs-18'>
                                     <StripTags policyText={data?.text} />
                                </p>
                            </div>
    
                            <div className='d-flex justify-content-center gap-sm-5 my-5'>
                                <div className='d-flex flex-column flex-sm-row align-items-center'>
                                    <img className='pt-4 width-25' src={clock} alt="clock" />
                                    <div className='d-flex flex-column ps-sm-3'>
                                        <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Opening Hours</span>
                                        <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={data?.opening_hour} /></span>
                                    </div>
                                </div>
                                <div className='d-flex flex-column flex-sm-row align-items-center'>
                                    <img className='pt-4 width-25' src={access} alt="clock" />
                                    <div className='d-flex flex-column mx-4 mx-sm-0 ps-sm-3'>
                                        <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Access</span>
                                        <span className='text-prime fs-18 text-center text-sm-start'>No Membership Required</span>
                                    </div>
                                </div>
                                <div className='d-flex flex-column flex-sm-row align-items-center'>
                                    <img className='pt-4 width-25' src={dress} alt="clock" />
                                    <div className='d-flex flex-column ps-sm-3'>
                                        <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Dress Code</span>
                                        <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={data?.dress_code} /></span>
                                    </div>
                                </div>
                                {(data?.carte || data?.link) && (
                                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                                        <img className='pt-4 width-25 ms-3 ms-sm-0' src={cloch} alt="clock" />
                                        <div className='d-flex flex-column ps-3'>
                                            <span className='fs-18 fw-bold text-center text-prime text-sm-start'>La Carte</span>
                                            <a className='text-prime fs-18 text-center text-sm-start text-decoration-none pointer' href={data?.carte ? data?.carte : data?.link}>A La Carte</a>
                                        </div>
                                    </div>
                                )}
                            </div>
    
                             <Slider {...settings}>         
                                {data?.images?.map((image, imageIndex) => (
                                    <div key={imageIndex} className='picture-wrapper padding-top-40 my-4'>
                                        <img src={image?.image_name} alt='banner-table' />
                                    </div>
                                ))} 
                            </Slider>
                        </div>  
                    ))}
                      
                </div>
                <div class={`tab-pane fade ${searchTab === 'la-martingale-terrase' ? 'show active' : ''}  w-100`} id="martingale_terrace" aria-labelledby="pills-contact-directory-tab">
                {dining && dining?.filter((data) => data?.title.replace(/\s+/g, '').toLowerCase() === 'La Martingale Terrace'.replace(/\s+/g, '').toLowerCase()).map((data,index) => (
                    <div key={index} className='w-60 mx-auto p-4'>
                        <div className='main-title-v2'><span className='h3'>{data?.title}</span></div>
                        <div className='main-description'>
                            <p className='text-center w-75 fs-18'>
                                <StripTags policyText={data?.text} />
                            </p>
                        </div>
                        
                        <div className='d-flex justify-content-center gap-sm-5 my-5'>
                            <div className='d-flex flex-column flex-sm-row align-items-center'>
                                <img className='pt-4 width-25' src={clock} alt="clock" />
                                <div className='d-flex flex-column ps-sm-3'>
                                    <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Opening Hours</span>
                                    <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={data?.opening_hour} /></span>
                                </div>
                            </div>
                            <div className='d-flex flex-column flex-sm-row align-items-center'>
                                <img className='pt-4 width-25' src={access} alt="clock" />
                                <div className='d-flex flex-column mx-4 mx-sm-0 ps-sm-3'>
                                    <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Access</span>
                                    <span className='text-prime fs-18 text-center text-sm-start'>No Membership Required</span>
                                </div>
                            </div>
                            <div className='d-flex flex-column flex-sm-row align-items-center'>
                                <img className='pt-4 width-25' src={dress} alt="clock" />
                                <div className='d-flex flex-column ps-sm-3'>
                                    <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Dress Code</span>
                                    <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={data?.dress_code} /></span>
                                </div>
                            </div>
                            {(data?.carte || data?.link) && (
                                <div className='d-flex flex-column flex-sm-row align-items-center'>
                                    <img className='pt-4 width-25 ms-3 ms-sm-0' src={cloch} alt="clock" />
                                    <div className='d-flex flex-column ps-3'>
                                        <span className='fs-18 fw-bold text-center text-prime text-sm-start'>La Carte</span>
                                        <a className='text-prime fs-18 text-center text-sm-start text-decoration-none pointer' href={data?.carte ? data?.carte : data?.link}>A La Carte</a>
                                    </div>
                                </div>
                            )}
                        </div>


                        <Slider {...settings}>         
                            {data?.images?.map((image, imageIndex) => (
                                <div key={imageIndex} className='picture-wrapper padding-top-40 my-4'>
                                    <img src={image?.image_name} alt='banner-table' />
                                </div>
                            ))} 
                        </Slider>
                    </div> 
                ))}   
                </div>
            </div>
        </div>
    </div>

    </>
  )
}

export default Dining