
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiTypography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
    borderBottom: `1px solid #927238`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    }));
    
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowRightIcon sx={{ fontSize: '1.5rem' , color: '#927238' }} />}
        {...props}
    />
    ))(({ theme }) => ({
    // backgroundColor: '#b0a06c',
    color: '#927238',
    fontWeight: 'bold',
    padding: 0,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const Accordion_v2 = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
    borderBottom: `1px solid #ffffff`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    backgroundColor: '#927238',
    }));
    
const AccordionSummary_v2 = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowRightIcon sx={{ fontSize: '1.5rem' , color: '#ffffff' }} />}
        {...props}
    />
    ))(({ theme }) => ({

    color: '#ffffff',
    fontWeight: 'bold',
    marginLeft: '25px',
    paddingRight: 0,
    width: '350px',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionTypography_v2 = styled(MuiTypography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: 'clamp(14px,0.93vw,18px)', 
}));

const AccordionDetails_v2 = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    fontWeight: 'bold',
    paddingLeft: '50px',
    borderBottom:'1px solid #927238',
    
}));



export { Accordion, AccordionSummary , AccordionTypography_v2 , AccordionDetails,Accordion_v2,AccordionSummary_v2,AccordionDetails_v2}