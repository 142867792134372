
import './App.css';
import { BrowserRouter as Router , Routes ,Route} from 'react-router-dom';
import Navbar from './web/layouts/Navbar';
import Home from './web/pages/Home';
import Footer from './web/layouts/Footer';
import Contact from './web/pages/Contact';
import Gallery from './web/pages/Gallery';
import SingleGallery from './web/pages/SingleGallery';
import TableGames from './web/pages/TableGames';
import Slots from './web/pages/Slots';
import Events from './web/pages/Events';
import SingleEvent from './web/pages/SingleEvent';
import News from './web/pages/News';
import AboutUs from './web/pages/AboutUs';
import Rules from './web/pages/Rules';
import Dining from './web/pages/Dining';
import ResponsibleGaming from './web/pages/ResponsibleGaming';
import Faq from './web/pages/Faq';
import Policy from './web/pages/Policy';
import Rewards from './web/pages/Rewards';
import Travel from './web/pages/Travel';
import Poker from './web/pages/Poker';
import SubGallery from './web/pages/SubGallery';
import TravelDetail from './web/pages/TravelDetail';

function App() {
  return (
    <>
      <Router>
          <Navbar />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/contact-us' element={<Contact />} />
                <Route path='/gallery' element={<Gallery />} />
                <Route path='/gallery/:id' element={<SubGallery />} />
                <Route path='/gallery/sub-gallery/:id' element={<SingleGallery />} />
                <Route path='/table-games' element={<TableGames />} />
                <Route path='/travel-to-lebanon' element={<Travel />} />
                <Route path='/travel-to-lebanon/:id' element={<TravelDetail />} />
                <Route path='/slots-palace' element={<Slots />} />
                <Route path="/events" element={<Events />} />
                <Route path="/events/:id" element={<SingleEvent />} />
                <Route path="/news" element={<News />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/rules-&-admissions" element={<Rules />} />
                <Route path="/dining" element={<Dining />} />
                <Route path="/responsible-gaming" element={<ResponsibleGaming />} />
                <Route path="/cdl-rewards" element={<Rewards />} />
                <Route path="/poker" element={<Poker />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/policy" element={<Policy />} />
            </Routes>
          <Footer />
      </Router>
    </>
  );
}

export default App;
