import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../components/Accordion";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import banner from "../../assets/storage/responsible-gaming/problem-gambling.png";
import axios from "axios";
import StripTags from "../components/StripTags";
import apiUrl from "../../config";

const ResponsibleGaming = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [gambling, setGambling] = useState([]);
  const [responsible, setResponsible] = useState({});
  const [programsList, setProgramsList] = useState([]);

  useEffect(() => {
      const fetchResponsible = async () => {
        const res = await axios.get(`${apiUrl}/api/responsible`);
        setResponsible(res.data.responsible_gaming);
        setProgramsList(res.data.program_list);
      }
      fetchResponsible()

      const fetchGambling = async () => {
        const res = await axios.get(`${apiUrl}/api/responsible/gambling`);
        setGambling(res.data);
      }
      fetchGambling()
  }, []) 



  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
    <div className="container">
        <div className="responsible-gaming">
          <div className="navs py-5">
            <div className="w-60 mx-auto">
              <ul
                class="nav nav-pills nav-fill justify-content-center flex-md-nowrap mb-3 w-100"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item w-100" role="presentation">
                  <button
                    class="nav-link fs-18 active"
                    id="pills-customer-service-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-customer-service"
                    type="button"
                    role="tab"
                    aria-controls="pills-customer-service"
                    aria-selected="true"
                  >
                    Problem Gambling
                  </button>
                </li>
                <li class="nav-item w-100" role="presentation">
                  <button
                    class="nav-link fs-18"
                    id="pills-contact-directory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact-directory"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact-directory"
                    aria-selected="false"
                  >
                    Responsible Gaming Program
                  </button>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-customer-service"
                role="tabpanel"
                aria-labelledby="pills-customer-service-tab"
              >
                <div className="w-60 mx-auto p-4">
                  <div>
                    <div className="main-title">
                      <span className="h3">Responsible Gaming</span>
                    </div>
                    <div className="main-description">
                      <p className="text-center mb-3 fs-18 w-75">
                        <StripTags policyText={responsible?.description} />
                      </p>
                    </div>

                    {gambling && gambling?.map((data,index) => (
                        <div key={index}>
                            <div className='picture-wrapper padding-top-40 my-4'>
                                <img src={data?.image} alt='banner-table' />
                            </div>

                            <div className="main-description">
                              <p className="text-center w-75">
                                <span className="fw-bold text-prime">
                                  {" "}
                                  {data?.title}
                                </span>
                                <br />
                                <StripTags policyText={data?.text} />
                              </p>
                            </div>
                        </div>
                    ))}
                </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact-directory"
                role="tabpanel"
                aria-labelledby="pills-contact-directory-tab"
              >
                <div className="w-60 mx-auto p-4">
                <div>
                  {responsible && (
                    
                    <>  
                      <div className="main-title ">
                        <span className="h3">Responsible Gaming</span>
                      </div>
                      <div className="main-description">
                        <p className="text-center fs-18 mb-3 w-75">
                          <StripTags policyText={responsible.description} />
                        </p>
                      </div>

                      <div className='picture-wrapper padding-top-40 my-4'>
                          <img src={responsible.image} alt='banner-table' />
                      </div>
                    </>

                  )}
               
                  {programsList && programsList?.map(({title,description},id) => (
                      <div key={id} className="list-responsible mt-4">
                      <Accordion
                        expanded={expanded === id}
                        onChange={handleChange(id)}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography>
                           {title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <StripTags policyText={description} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponsibleGaming;
